import { sortBy } from 'lodash-es';

import { sanitizeRichText } from 'components/form/richtext/RichTextInput';

import {
	Country,
	Offer,
	OfferDto,
	PersonType,
	StudentHardskill,
	OfferHardskill,
	Company,
	Student,
	CompanyEvent,
} from 'typing/endpoints';

export const isCountryCzechRepublic = (country: Country | null) =>
	country?.id === '4a1b50cf-cefa-426c-9e96-4cbb9a8f6154';

export const isEventActive = (event: CompanyEvent) =>
	event.activateFrom !== undefined;

export const getFormattedEventDate = (eventDate: Date) => {
	return `${new Date(eventDate).toLocaleDateString('cs')} | ${new Date(
		eventDate,
	).toLocaleTimeString('cs', {
		hour: '2-digit',
		minute: '2-digit',
	})}`;
};

export const isOfferActive = (
	offer?: Pick<Offer, 'isVisible' | 'validTo'> | OfferDto,
) => {
	if (!offer || !('isVisible' in offer)) {
		return true;
	}
	const validTo = new Date(offer.validTo);
	validTo.setHours(0, 0, 0, 0);
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return offer.isVisible && validTo.getTime() >= today.getTime();
};

export const getEntryRoute = (type?: PersonType) => (type ? '/dashboard' : '/');

export const getHardskillName = (
	hs?: StudentHardskill | OfferHardskill | null,
) => {
	if (!hs) {
		return '';
	}
	const n = hs?.hardskill?.name ?? hs?.suggestedHardskill?.name ?? '';
	return n[0].toLocaleUpperCase() + n.slice(1);
};

export const sanitizeFormRichText = (
	obj: Pick<Company | Offer | Student, 'leadParagraph' | 'descriptionPages'>,
) => ({
	leadParagraph: obj.leadParagraph ? sanitizeRichText(obj.leadParagraph) : '',
	descriptionPages: obj.descriptionPages
		? sortBy(obj.descriptionPages, 'order').map(dp => ({
				...dp,
				content: sanitizeRichText(dp.content),
		  }))
		: [],
});
