/** @jsxImportSource @emotion/core */
import { getIn, useFormik } from 'formik';
import React, { FC } from 'react';
import { MdInfo } from 'react-icons/md';
import { css } from '@emotion/core';

import Form from 'components/form';
import RichTextInput, {
	isRichTextEmpty,
} from 'components/form/richtext/RichTextInput';
import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';

import useBreakpoint from 'hooks/useBreakpoint';

import { getStudentProfileFormInitialValues } from '../profile/edit/config';

import { introFormSchemas } from './validations';

import { FormCommonProps, IntroShadowButton, SectionLabel } from '.';

const IntroForm2: FC<FormCommonProps> = ({ student, afterSubmit, formId }) => {
	const shouldUpdateProfileCPLT = isRichTextEmpty(student.leadParagraph);

	const formikContext = useFormik({
		initialValues: getStudentProfileFormInitialValues(student),
		validationSchema: introFormSchemas.form2,
		onSubmit: values => {
			afterSubmit(values, shouldUpdateProfileCPLT ? 25 : 0);
		},
	});

	const break1 = useBreakpoint(3);
	const break2 = useBreakpoint(0);

	const {
		handleSubmit,

		values,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
		submitCount,
	} = formikContext;
	const wasSubmitted = submitCount !== 0;

	return (
		<Form id={formId} onSubmit={handleSubmit} width={1}>
			<Flex flexWrap="wrap" width={1} alignItems="center">
				<Flex width={1} flexDirection="column">
					<SectionLabel label="O mně" percents={25} />
					<RichTextInput
						id="leadParagraph"
						value={values.leadParagraph}
						placeholder="Např.: Jsem marketingový nadšenec se zájmem o psychologii. Dosud jsem pracoval na menších projektech, nyní chci být součástí něčeho většího. Chci se učit novým věcem a pracovat na sobě."
						error={getIn(errors, 'leadParagraph')}
						touched={wasSubmitted || getIn(touched, 'leadParagraph')}
						disabled={isSubmitting}
						onSetValue={setFieldValue}
						onSetTouched={setFieldTouched}
						isIntroductionForm
					/>
					<Flex color="grey" mt={3} alignItems="center">
						<Text
							css={css`
								white-space: pre-wrap;
							`}
						>
							<MdInfo />
							<Text ml={2} as="span">
								Medailonek je zobrazen hned pod tvojí profilovou fotkou{' '}
								{!break1 || break2 ? '' : <br />} a kontaktními údaji.
							</Text>
						</Text>
					</Flex>
				</Flex>

				<Box width={1} mt={4} textAlign="center">
					<IntroShadowButton
						form={formId}
						variant="primary"
						type="submit"
						width={2 / 3}
					>
						Další
					</IntroShadowButton>
				</Box>
			</Flex>
		</Form>
	);
};

export default IntroForm2;
