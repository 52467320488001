import * as Yup from 'yup';

import { companyAddressValidationSchema } from 'utils/address';
import {
	maxCharMessage,
	requiredRichTextSchema,
	richTextMaxCharCount,
	shortStringSchema,
} from 'utils/validation';

export const companyEventValidationSchema = Yup.object({
	newPhoto: Yup.mixed().when('photo', {
		is: val => {
			return val === null || val === undefined;
		},
		then: Yup.mixed().test('size', 'Požaované', fileSize =>
			fileSize ? true : false,
		),
		otherwise: Yup.mixed().nullable(),
	}),
	title: shortStringSchema.required('Požadované'),
	description: requiredRichTextSchema.concat(richTextMaxCharCount(3000)),
	businessSectors: Yup.array().of(Yup.object()).required('Požadované'),
	from: Yup.date().required('Požadované').typeError('Požadované'),
	to: Yup.date().required('Požadované').typeError('Požadované'),
	address: Yup.mixed().when('isOnline', {
		is: false,
		then: companyAddressValidationSchema,
	}),

	descriptionPages: Yup.array(
		Yup.object({
			title: shortStringSchema.nullable().required('Požadované'),
			content: requiredRichTextSchema.concat(richTextMaxCharCount(2000)),
		}),
	),

	eventUrl: Yup.string()
		.trim()
		.max(200, maxCharMessage(200))
		.required('Požadované'),
});
