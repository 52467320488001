/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC } from 'react';

import RevealStudentButton from 'components/tiles/student/RevealStudentButton';

import {
	LockClosedIcon,
	LockOpenIcon,
	MedalPortfolioIcon,
	ProfileIconI,
} from 'assets';
import { Theme } from 'theme';

import logoPlaceholder from 'assets/company-avatar-placeholder.png';

import { Box, Flex, FlexProps } from '.';

type Props = FlexProps & {
	photo?: string;
	placeholder?: 'person' | 'company';
	variant?: 'tiles' | 'profile';
	hasMedal?: boolean;
	customMedalRenderer?: () => JSX.Element;
} & (
		| { isHidden?: false; onClick?: () => void }
		| { isHidden: true; identifier: string }
	);

const Avatar: FC<Props> = ({
	photo,
	placeholder = 'person',
	children,
	variant = 'profile',
	hasMedal = false,
	customMedalRenderer,
	...props
}) => (
	<Flex>
		<Flex
			position="relative"
			alignItems="center"
			justifyContent="center"
			color="white"
			flexShrink={0}
			{...props}
			css={(theme: Theme) => css`
				background-color: ${theme.colors.darkerGrey};
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				border-radius: 50%;
				overflow: hidden;

				${props.isHidden &&
				css`
					[data-unlocked] {
						display: none;
					}

					:not(:disabled):hover {
						background-color: ${theme.colors.primary};
						[data-unlocked] {
							display: block;
						}
						[data-locked] {
							display: none;
						}
					}
				`}

				${photo &&
				css`
					background-image: url(${photo});
				`}
			`}
		>
			{props.isHidden && (
				<RevealStudentButton identifier={props.identifier}>
					<LockClosedIcon color="white" size="40%" data-locked />
					<LockOpenIcon color="white" size="40%" data-unlocked />
				</RevealStudentButton>
			)}
			{!props.isHidden &&
				!photo &&
				(placeholder === 'person' ? (
					<ProfileIconI size="40%" />
				) : (
					<Box
						as={p => <img src={logoPlaceholder} alt="Logo" {...p} />}
						size="100%"
					/>
				))}

			{children}
		</Flex>
		{customMedalRenderer ? (
			customMedalRenderer()
		) : (
			<>
				{hasMedal && (
					<Flex
						position={variant === 'profile' ? 'absolute' : 'relative'}
						bottom="-40px"
						right={variant === 'profile' ? 0 : '40px'}
						width={variant === 'tiles' ? '0px' : 'unset'}
					>
						<MedalPortfolioIcon
							size={variant === 'profile' ? 90 : 40}
							flexShrink={0}
						/>
					</Flex>
				)}
			</>
		)}
	</Flex>
);
export default Avatar;
