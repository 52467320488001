import { css } from '@emotion/core';

import { Theme } from 'theme';

import { FocusStyle } from 'theme/GlobalStyles';

export const QuillContentStyles = (theme: Theme) => css`
	.ql-editor {
		p,
		li {
			margin: ${theme.space[2]}px 0 !important;
			font-size: initial !important;
			line-height: initial !important;
		}

		h1,
		h2,
		ul,
		ol {
			margin: ${theme.space[3]}px 0 !important;
		}

		h1 {
			font-size: ${theme.fontSizes.xl}px !important;
			font-weight: bold !important;
			color: ${theme.colors.primary} !important;
		}

		h2 {
			font-size: ${theme.fontSizes.lg}px !important;
			font-weight: bold !important;
		}

		ul,
		ol {
			list-style: none !important;
			padding-left: 0 !important;

			li::before {
				color: ${theme.colors.primary} !important;
				line-height: 0 !important;
				font-weight: bold !important;
				padding-right: 8px !important;
			}
		}

		ul li::before {
			content: '-';
		}

		ol {
			counter-reset: list-counter;
			li {
				counter-increment: list-counter;
			}
			li::before {
				content: counter(list-counter) '. ';
			}
		}

		a {
			color: ${theme.colors.primary};
		}
	}
`;

export const QuillEditorStyles = (
	theme: Theme,
	error?: boolean,
	touched?: boolean,
	disabled?: boolean,
) => css`
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	:focus-within {
		${FocusStyle(theme)}
	}

	/* Placeholder */
	& .ql-editor.ql-blank::before {
		content: none;
	}

	& .ql-editor.ql-blank::after {
		color: rgba(0, 0, 0, 0.6);
		content: attr(data-placeholder);
		display: block;
		font-style: italic;
		margin-top: -27px;
		min-height: 1em;
		pointer-events: none;
	}

	/* Toolbar */
	& .ql-picker-label {
		stroke: white !important;
		color: white !important;
		padding: 0 5px !important;
	}

	& .ql-stroke {
		stroke: white !important;
	}

	& .ql-picker-options .ql-stroke {
		stroke: #444 !important;
	}

	& .ql-fill {
		fill: white !important;
	}

	& .ql-active .ql-stroke {
		stroke: ${theme.colors.secondary} !important;
	}

	& button:hover .ql-stroke {
		stroke: white !important;
	}

	& .ql-active .ql-fill {
		fill: ${theme.colors.secondary} !important;
	}

	& button:hover .ql-fill {
		fill: white !important;
	}

	& .ql-align .ql-active .ql-stroke {
		stroke: white !important;
	}

	& select:hover .ql-picker-label {
		stroke: white !important;
		color: white !important;
	}

	& .ql-picker-item:hover {
		stroke: black !important;
		color: black !important;
	}

	/* Editor */
	& .ql-container {
		margin: ${theme.space[3]}px;
		margin-top: 0;
		border: none !important;
		border-bottom: 1px solid
			${error && touched ? theme.colors.error : theme.colors.primary} !important;
		cursor: ${disabled ? 'not-allowed' : 'text'};
		flex-grow: 1;
		min-height: ${theme.space[5]}px;
		height: auto !important;

		& .ql-editor:focus {
			box-shadow: none;
		}
	}

	/* Video resize */
	& .blot-formatter__toolbar-button {
		background: ${theme.colors.primary} !important;
		border: none !important;
		svg {
			background: none !important;
			border: none !important;
		}
	}
`;
