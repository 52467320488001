/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import { getIn, useFormik } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { MdInfo, MdStar } from 'react-icons/md';

import Form from 'components/form';
import TextAreaInput from 'components/form/input/TextAreaInput';
import LanguageSelect from 'components/form/languages/LanguageSelect';
import RemoteSelect from 'components/form/select/RemoteSelect';
import SelectInput from 'components/form/select/SelectInput';
import HelpDialog from 'components/modal/HelpDialog';
import { Box, Flex } from 'components/styled';
import Chip from 'components/styled/Chip';
import Text from 'components/styled/Text';

import { CrossIcon } from 'assets';

import { useSoftskills } from 'api/softskillApi';

import useNewSuggestedHardskills from 'hooks/useNewSuggestedHardskills';

import { getHardskillName } from 'utils/UASHelpers';

import { getStudentProfileFormInitialValues } from '../profile/edit/config';
import SoftskillsHelpDialog from '../profile/edit/SoftskillsHelpDialog';

import { introFormSchemas } from './validations';

import {
	FormCommonProps,
	IntroPaper,
	IntroShadowButton,
	SectionLabel,
} from '.';

import { Softskill, StudentHardskill } from 'typing/endpoints';

const IntroForm3: FC<FormCommonProps> = ({ student, afterSubmit, formId }) => {
	// Form
	const formikContext = useFormik({
		initialValues: getStudentProfileFormInitialValues(student),
		validationSchema: introFormSchemas.form3,
		onSubmit: values => {
			const hsNewScore =
				(student.studentHardskills ?? []).length === 0 ? 15 : 0;
			const ssNewScore =
				(student.studentSoftskills ?? []).length === 0 ? 15 : 0;
			afterSubmit(values, hsNewScore + ssNewScore);
		},
	});
	const {
		handleSubmit,
		handleBlur,
		handleChange,
		values,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
		setFieldTouched,
		submitCount,
	} = formikContext;

	const studentRecomm = useMemo(
		() => [
			...student.educations.flatMap(e => e.studyBranch?.hardskills),
			...student.educations.flatMap(e => e.studyProgramme?.hardskills),
		],
		[student.educations],
	);

	const { selectProps } = useNewSuggestedHardskills<StudentHardskill>(
		student.suggestedHardskills,
		(lhs, rhs) => {
			const lhsName = getHardskillName(lhs);
			const rhsName = getHardskillName(rhs);

			const lRecomm = studentRecomm.find(hs => hs?.name === lhsName);
			const rRecomm = studentRecomm.find(hs => hs?.name === rhsName);

			if (!lRecomm && rRecomm) {
				return 1;
			}
			if (lRecomm && !rRecomm) {
				return -1;
			}

			if (lhsName > rhsName) {
				return 1;
			}
			if (lhsName < rhsName) {
				return -1;
			}

			return 0;
		},
	);

	const labelFromHardskill = useCallback((hs: StudentHardskill | null) => {
		if (!hs) {
			return '';
		}

		const hsName = getHardskillName(hs);

		return hsName;
	}, []);

	const onSetSoftskill = useCallback(
		(_id: string, value: Softskill[] | null) => {
			if (!value) {
				setFieldValue('studentSoftskills', null);
				return;
			}

			if (value.length > values.studentSoftskills.length) {
				const newHardskills = value.filter(
					h => !values.studentSoftskills.find(sh => sh.softskill.id === h.id),
				);
				setFieldValue('studentSoftskills', [
					...values.studentSoftskills,
					...newHardskills.map(softskill => ({ softskill })),
				]);
				return;
			}

			if (value.length < values.studentSoftskills.length) {
				setFieldValue(
					'studentSoftskills',
					values.studentSoftskills.filter(sh =>
						value.find(h => sh.softskill.id === h.id),
					),
				);
				return;
			}
		},
		[setFieldValue, values.studentSoftskills],
	);

	const wasSubmitted = submitCount !== 0;

	return (
		<Form id={formId} onSubmit={handleSubmit} width={1}>
			<Flex flexWrap="wrap" width={1} alignItems="center">
				<Flex flexDirection="column" width={1}>
					<SectionLabel label="Tvrdé dovednosti (aspoň 2)" percents={15} />
					<IntroPaper>
						<Text fontWeight="light" fontSize="sm" px={2} color="grey">
							<MdInfo />
							<Text ml={2} as="span">
								Mezi tvrdé dovednosti patří např. technické dovednosti, odborné
								dovednosti, ovládání PC programů...
							</Text>
						</Text>

						<Box width={1} mb={3}>
							<Flex maxWidth="100%" flexWrap="wrap" mb={2}>
								{values.studentHardskills.map(sh => (
									<Chip
										key={sh.id}
										size="small"
										variant="withCross"
										onDelete={() =>
											setFieldValue(
												'studentHardskills',
												values.studentHardskills.filter(val => val !== sh),
											)
										}
									>
										<Flex
											mr={2}
											title="Označit jako top dovednost"
											alignItems="center"
											color={sh.isTopFive ? 'golden' : 'textLight'}
											onClick={() =>
												setFieldValue(
													'studentHardskills',
													values.studentHardskills.map(val =>
														val === sh
															? { ...val, isTopFive: !sh.isTopFive }
															: val,
													),
												)
											}
											css={css`
												cursor: pointer;
											`}
										>
											<MdStar width={23} />
										</Flex>
										{getHardskillName(sh)}
									</Chip>
								))}
							</Flex>
							<Flex>
								<SelectInput
									id="studentHardskills"
									placeholder="Hvězdičkou označ své top dovednosti"
									value={values.studentHardskills}
									multiselect
									onSetValue={setFieldValue}
									onSetTouched={setFieldTouched}
									error={getIn(errors, 'studentHardskills')}
									touched={wasSubmitted || getIn(touched, 'studentHardskills')}
									disabled={isSubmitting}
									hideInlineSelectItems
									noOptionsText=""
									unsorted
									{...selectProps}
									labelFromOption={labelFromHardskill}
								/>
							</Flex>
						</Box>
					</IntroPaper>

					{/* Softskills */}
					<SectionLabel label="Měkké dovednosti (stačí 1)" percents={15} />
					<IntroPaper>
						<Text fontWeight="light" fontSize="sm" px={2} color="grey">
							<MdInfo />
							<Text ml={2} as="span">
								Měkké dovednosti jsou kompetence v oblasti chování, např.
								kritické myšlení, schopnost učit se, komunikace...
							</Text>
						</Text>
						<Flex maxWidth="100%" flexDirection="column" mb={2}>
							{values.studentSoftskills.map((ss, index) => (
								<Chip
									key={ss.softskill.id}
									size="small"
									flexDirection="column"
									alignItems="stretch"
								>
									<Flex justifyContent="space-between">
										<Flex mx={1} my={0}>
											{ss.softskill.name}
										</Flex>
										<Box
											onClick={() =>
												setFieldValue(
													'studentSoftskills',
													values.studentSoftskills.filter(val => val !== ss),
												)
											}
											css={css`
												cursor: pointer;
											`}
										>
											<CrossIcon width={23} color="primary" />
										</Box>
									</Flex>

									<TextAreaInput
										id={`studentSoftskills[${index}].reasoning`}
										name={`studentSoftskills[${index}].reasoning`}
										placeholder="Uveď příklad z praxe - např. jsem silný v organizaci práce, kterou jsem si mohl vyzkoušet na organizaci logistiky zboží v minulé brigádě"
										value={ss.reasoning}
										onChange={handleChange}
										onBlur={handleBlur}
										error={getIn(
											errors,
											`studentSoftskills[${index}].reasoning`,
										)}
										touched={
											wasSubmitted ||
											getIn(touched, `studentSoftskills[${index}].reasoning`)
										}
										disabled={isSubmitting}
										minRows={2}
									/>
								</Chip>
							))}
						</Flex>
						<Flex>
							<RemoteSelect<Softskill>
								id="studentSoftskills"
								placeholder="Vyber ze seznamu"
								value={values.studentSoftskills.map(s => s.softskill)}
								multiselect
								onSetValue={onSetSoftskill}
								onSetTouched={setFieldTouched}
								disabled={isSubmitting}
								hideInlineSelectItems
								apiCall={useSoftskills}
								error={getIn(errors, `studentSoftskills`)}
								touched={wasSubmitted || getIn(touched, `studentSoftskills`)}
							/>
							<HelpDialog label="Dialog nápovědy k měkkým dovednostem">
								<SoftskillsHelpDialog />
							</HelpDialog>
						</Flex>
					</IntroPaper>
					<SectionLabel label="Jazykové znalosti" />
					<IntroPaper>
						<LanguageSelect
							id="languages"
							placeholder="Můžeš zvolit více možností"
							multiselect
							value={values.languages}
							onSetValue={setFieldValue}
							showHint
						/>
					</IntroPaper>
				</Flex>

				<Box width={1} mt={4} textAlign="center">
					<IntroShadowButton
						form={formId}
						variant="primary"
						type="submit"
						width={2 / 3}
					>
						Další
					</IntroShadowButton>
				</Box>
			</Flex>
		</Form>
	);
};

export default IntroForm3;
