import { createContext } from 'react';

export const TutorialCompanyProfileContext = createContext({
	currentStep: 0,
	isOpen: false,
	wasPrematurlyClosed: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setStep: (step: number) => {
		// shut
	},
	showTour: () => {
		// the
	},
	closeTour: () => {
		// f*ck
	},
	forceUpdate: () => {
		// up
	},
});
