import React from 'react';

import Text from 'components/styled/Text';

const TitleText: React.FC<
	{ variant?: 'primary' } & React.ComponentProps<typeof Text>
> = ({ variant, children, ...props }) => (
	<Text
		as="h2"
		fontSize={['lg', 'xl']}
		fontWeight="bold"
		textAlign="center"
		p={[2, 3]}
		color={variant === 'primary' ? 'primary' : 'text'}
		{...props}
	>
		{children}
	</Text>
);
export default TitleText;
