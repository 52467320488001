import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Tour from '@slavikdenis/reactour';
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { ClassNames } from '@emotion/core';
import { useHistory, useLocation } from 'react-router-dom';

import useTour from 'modules/tutorials/useTour';

import { useUserToken } from 'auth';

import { setTutorialCompleted } from 'api/personApi';

import { isRecruiterUser } from 'auth/token';

import store from 'utils/Store';

import PrematureCloseDialog from './extra/PrematureCloseDialog';
import { TutorialCompanyProfileContext as Context } from './_context';
import COMPANY_PROFILE_TUTORIAL_STEPS from './_steps';

const { Provider } = Context;

export const TutorialCompanyProfileProvider: React.FC = ({ children }) => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	const user = useUserToken();

	// Force update state
	const [updateFlag, setUpdateFlag] = useState(false);
	const forceUpdate = useCallback(() => setUpdateFlag(u => !u), []);

	// Premature close modal
	const [isDialogOpen, showDialog] = useState(false);

	// Hooks
	const { closeTour, currentStep, isOpen, setStep, showTour } = useTour({
		defaultStep: store.get<number>(store.keys.TutorialCompanyProfileStep, -1),
	});

	// Handlers
	const handleSetAndPreserveStep = useCallback(
		(step: number) => {
			store.set(store.keys.TutorialCompanyProfileStep, step);
			setStep(step);
		},
		[setStep],
	);

	const onRequestClose = useCallback(() => {
		// Close tour
		closeTour();
		// Show premature close dialog
		if (currentStep !== COMPANY_PROFILE_TUTORIAL_STEPS.length - 1) {
			showDialog(true);
			setTutorialCompleted(true);
		}
	}, [currentStep, closeTour]);

	const wasPrematurlyClosed = useMemo(
		() => !isOpen && currentStep !== COMPANY_PROFILE_TUTORIAL_STEPS.length - 1,
		[currentStep, isOpen],
	);

	// Effects
	useEffect(() => {
		clearAllBodyScrollLocks();
	}, []);

	useEffect(() => {
		if (!isOpen) {
			clearAllBodyScrollLocks();
		}
	}, [isOpen]);

	// Company tutorial
	const isCompanyTutorialEnabled =
		!user?.tutorialCompleted && isRecruiterUser(user);

	// Reset and show company tutorial if enabled
	useEffect(() => {
		if (!isCompanyTutorialEnabled) {
			return;
		}

		if (currentStep <= 0) {
			setStep(0);
			push('/orders');
			showTour();
		} else if (currentStep >= 1) {
			if (!pathname.match('/company/edit')) {
				console.log('PUSHING COMPANY EDIT');
				push('/company/edit');
			}

			showTour();
		} else if (currentStep >= 4) {
			//	!pathname.match('/company/edit/') && push('/company/edit/');
			//	showTour();
		}
	}, [
		isCompanyTutorialEnabled,
		currentStep,
		showTour,
		setStep,
		pathname,
		push,
	]);

	return (
		<Provider
			value={{
				currentStep,
				isOpen,
				setStep: handleSetAndPreserveStep,
				closeTour,
				showTour,
				forceUpdate,
				wasPrematurlyClosed,
			}}
		>
			<PrematureCloseDialog
				isOpen={isDialogOpen}
				onDismiss={() => showDialog(false)}
			/>

			<ClassNames>
				{({ css }) => (
					<Tour
						startAt={currentStep}
						goToStep={currentStep}
						getCurrentStep={handleSetAndPreserveStep}
						steps={COMPANY_PROFILE_TUTORIAL_STEPS}
						isOpen={isOpen}
						onRequestClose={onRequestClose}
						update={String(updateFlag)}
						updateDelay={100}
						rounded={8}
						disableKeyboardNavigation
						badgeContent={(curr, tot) => `Krok ${curr}/${tot}`}
						showNavigation={false}
						onAfterOpen={target => disableBodyScroll(target)}
						onBeforeClose={target => enableBodyScroll(target)}
						disableFocusLock
						closeWithMask={false}
						disablePrevStepButton={currentStep === 1}
						className={css`
							max-width: calc(90%);
						`}
						disableNextStepButton={currentStep === 13}
						showButtons={
							currentStep !== 0 &&
							currentStep !== COMPANY_PROFILE_TUTORIAL_STEPS.length - 1
						}
						showCloseButton={
							currentStep !== 0 &&
							currentStep !== COMPANY_PROFILE_TUTORIAL_STEPS.length - 1
						}
					/>
				)}
			</ClassNames>

			{children}
		</Provider>
	);
};
