import * as Yup from 'yup';
import { pick } from 'lodash-es';

import {
	mergeSchemas,
	phoneValidationSchema,
	requiredRichTextSchema,
	richTextMaxCharCount,
} from 'utils/validation';

import { studentValidationSchemaObject } from '../profile/edit/config';

import { BusinessSector, StudentHardskill } from 'typing/endpoints';

export const introFormSchemas = {
	form1: Yup.object({
		contactEmail: Yup.string()
			.email('Neplatná emailová adresa')
			.required('Požadované'),
		phone: phoneValidationSchema,
	}),

	form2: Yup.object({
		leadParagraph: requiredRichTextSchema.concat(richTextMaxCharCount(2000)),
	}),
	form3: mergeSchemas(
		Yup.object({
			studentHardskills: Yup.array<StudentHardskill>()
				.test('length', 'Vyber alespoň dvě tvrdé dovednosti.', val => {
					if (!val) {
						return false;
					}
					if (val.length < 2) {
						return false;
					}
					return true;
				})
				.test(
					'top-5',
					'Můžeš mít maximálně 5 top dovedností',
					val => !val || val.filter(sh => sh.isTopFive).length <= 5,
				)
				.required('Vyber alespoň dvě tvrdé dovednosti.'),
		}),
		Yup.object(pick(studentValidationSchemaObject, 'studentSoftskills')),
	),

	form4: Yup.object({
		jobExperience: requiredRichTextSchema.concat(richTextMaxCharCount(2000)),
		businessSectors: Yup.array<BusinessSector>().required('Požadované'),
	}),
};
