import { useEffect, useCallback, useState } from 'react';

import { useTheme } from 'theme';

const useBreakpoint = (index: number) => {
	const { breakpoints } = useTheme();

	const query = window.matchMedia(`(max-width: ${breakpoints[index]})`);

	const [queryResult, setQueryResult] = useState(query.matches);

	const watcher = useCallback(() => {
		setQueryResult(query.matches);
	}, [query, setQueryResult]);

	useEffect(() => {
		query.addListener(watcher);
		return () => query.removeListener(watcher);
	}, [query, watcher]);

	return queryResult;
};
export default useBreakpoint;

export const useMobileView = (maxWidth = 450) => {
	const query = window.matchMedia(`(max-width: ${maxWidth}px)`);

	const [queryResult, setQueryResult] = useState(query.matches);

	const watcher = useCallback(() => {
		setQueryResult(query.matches);
	}, [query, setQueryResult]);

	useEffect(() => {
		query.addListener(watcher);
		return () => query.removeListener(watcher);
	}, [query, watcher]);

	return queryResult;
};
