import { Area } from 'react-easy-crop/types';
import { Orientation } from 'get-orientation/base';
import { v4 as uuid } from 'uuid';

import { FileAccept } from './FileInput';

export const getAcceptedFileTypes = (accept: string) => {
	switch (accept) {
		case FileAccept.Audio:
			return 'audio';
		case FileAccept.Video:
			return 'video';
		case FileAccept.Image:
			return '.jpg, .png a pod.';
		default:
			return accept;
	}
};

export function readFile(file: File): Promise<string> {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.addEventListener(
			'load',
			() => resolve(reader.result as string),
			false,
		);
		reader.readAsDataURL(file);
	});
}

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', error => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
		image.src = url;
	});

function getRadianAngle(degreeValue: number) {
	return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(
	imageSrc: string,
	pixelCrop: Area,
	rotation = 0,
): Promise<File> {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx?.translate(safeArea / 2, safeArea / 2);
	ctx?.rotate(getRadianAngle(rotation));
	ctx?.translate(-safeArea / 2, -safeArea / 2);

	// draw rotated image and store data.
	ctx?.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5,
	);
	const data = ctx?.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx?.putImageData(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		data!,
		0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
		0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
	);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	// return new Promise(resolve => {
	// 	canvas.toBlob(file => {
	// 		resolve(URL.createObjectURL(file));
	// 	}, 'image/jpeg');
	// });
	return new Promise(resolve => {
		canvas.toBlob(
			file => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				resolve(new File([file!], uuid(), { type: 'image/jpeg' }));
			},
			'image/jpeg',
			1, // set quality to max
		);
	});
}

export async function getRotatedImage(
	imageSrc: string,
	rotation = 0,
): Promise<string> {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const orientationChanged =
		rotation === 90 ||
		rotation === -90 ||
		rotation === 270 ||
		rotation === -270;
	if (orientationChanged) {
		canvas.width = image.height;
		canvas.height = image.width;
	} else {
		canvas.width = image.width;
		canvas.height = image.height;
	}

	ctx?.translate(canvas.width / 2, canvas.height / 2);
	ctx?.rotate((rotation * Math.PI) / 180);
	ctx?.drawImage(image, -image.width / 2, -image.height / 2);

	// As a blob
	return new Promise(resolve => {
		canvas.toBlob(file => {
			resolve(URL.createObjectURL(file));
		}, 'image/jpeg');
	});
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ORIENTATION_TO_ANGLE: Record<Orientation, number> = {
	[Orientation.BOTTOM_RIGHT]: 180,
	[Orientation.RIGHT_TOP]: 90,
	[Orientation.LEFT_BOTTOM]: -90,
};
