/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import '@reach/dialog/styles.css';
import Dialog, { DialogProps } from '@reach/dialog';
import React from 'react';

import { Box } from 'components/styled';
import Text from 'components/styled/Text';
import Link from 'components/styled/Link';

const PrematureCloseDialog: React.FC<Pick<
	DialogProps,
	'isOpen' | 'onDismiss'
>> = ({ isOpen, onDismiss }) => (
	<Dialog
		isOpen={isOpen}
		onDismiss={onDismiss}
		aria-label="Předčasné ukončení tutoriálu"
		css={css`
			background: #ffffff;
			border-radius: 8px;
		`}
	>
		<Box>
			<Text textAlign="center">
				Pro úspěšné dokončení registrace a aktivaci přejděte do sekce{' '}
				<Link to="/orders" color="primary">
					Moje objednávky
				</Link>{' '}
				a dokončete vaší objednávku.
			</Text>
		</Box>
	</Dialog>
);

export default PrematureCloseDialog;
