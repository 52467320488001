/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdHome } from 'react-icons/md';
import { IoMdBriefcase, IoMdCalendar } from 'react-icons/io';
import { FaHandshake } from 'react-icons/fa';

import { Box, Flex } from 'components/styled';
import { NavLinkButton } from 'components/styled/Button';
import Text from 'components/styled/Text';

import { ReactComponent as BusinessSectorIcon } from 'modules/company/profile/assets/businessSectorIcon.svg';

import { useTheme } from 'theme';

import { ApiUser, isStudentUser } from 'auth/token';

import { useScrollDirection } from 'hooks/useScrollDirection';

import {
	EMBED_URL,
	FORCED_PROFILE_EDIT_FORM,
	POPUP_REDICERT_PATH,
} from 'utils/enumMaps';

type Props = {
	user?: ApiUser;
	collapseWidth: string;
};

type Item = {
	label: string;
	to: string;
	Icon: React.FC<Record<string, unknown>>;
};

const items: Item[] = [
	{
		label: 'DOMŮ',
		to: '/dashboard',
		Icon: MdHome,
	},
	{
		label: 'NABÍDKY',
		to: '/search/offers',
		Icon: IoMdBriefcase,
	},
	{
		label: 'ZAMĚSTNAVATELÉ',
		to: '/search/companies',
		Icon: BusinessSectorIcon,
	},
	{
		label: 'AKCE',
		to: '/search/company-events',
		Icon: IoMdCalendar,
	},
	{
		label: 'STUDENTI\nSTUDENTŮM',
		to: '/students-to-students',
		Icon: FaHandshake,
	},
];

const StudentBottomMenu: FC<Props> = ({ user, collapseWidth }) => {
	const { pathname } = useLocation();
	const theme = useTheme();
	const { isVisible } = useScrollDirection();
	const [height, setHeight] = useState(0);
	useEffect(() => setHeight(isVisible ? 70 : 0), [isVisible]);

	if (!isStudentUser(user)) {
		return <></>;
	}

	// hide header if embed offers are used
	if (pathname === EMBED_URL) {
		return <></>;
	}

	if (pathname === FORCED_PROFILE_EDIT_FORM) {
		return <></>;
	}

	if (pathname.includes(POPUP_REDICERT_PATH)) {
		return <></>;
	}

	// if (!isVisible) {
	// 	return <></>;
	// }

	return (
		<Flex
			alignItems="flex-start"
			justifyContent="center"
			position="sticky"
			width={1}
			p={0}
			bg="primary"
			color="secondary"
			height={height}
			bottom={0}
			zIndex={9999}
			boxShadow="0px -1px 1px 1px rgba(0,0,0,0.1)"
			overflow="hidden"
			css={css`
				transition: height 0.3s ease-in-out;
				@media (min-width: ${collapseWidth}) {
					display: none !important;
				}
			`}
		>
			<Flex
				width={1}
				alignItems="flex-start"
				justifyContent="space-between"
				px={1}
			>
				{items.map(({ label, Icon, to }) => (
					<NavLinkButton
						to={to}
						variant="text"
						key={label}
						minWidth={50}
						flexShrink={0}
						color="secondary"
						mx={1}
						p={0}
						css={
							pathname === to
								? css`
										border-top: 5px solid ${theme.colors.secondary};

										&:focus {
											box-shadow: none;
										}
								  `
								: css`
										border-top: 5px solid ${theme.colors.primary};
								  `
						}
					>
						<Flex
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							color="secondary"
							lineHeight={1}
							pt="11.2px"
						>
							<Box mb={1}>
								<Icon height={20} size={20} />
							</Box>
							{label.split('\n').map(l => (
								<Text my={0} py={0} fontSize={11} fontWeight="bold" key={l}>
									{l}
								</Text>
							))}
						</Flex>
					</NavLinkButton>
				))}
			</Flex>
		</Flex>
	);
};

export default StudentBottomMenu;
