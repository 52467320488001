import decodeJWT from 'jwt-decode';
import isBefore from 'date-fns/isBefore';

import { api } from 'api';

import Store from 'utils/Store';

import { PersonType } from 'typing/endpoints';

type CommonProps = {
	sub: string;
	id: string;
	iat: number;
	exp: number;
	tutorialCompleted?: boolean;
	profileCompleteness?: number;
};

export type AdminUser = CommonProps & {
	type: Exclude<PersonType, 'RECRUITER' | 'STUDENT' | 'ALUMNUS'>;
};

export type StudentUser = CommonProps & {
	type: Extract<PersonType, 'STUDENT' | 'ALUMNUS'>;
	gdpr?: boolean | null;
};

export type RecruiterUser = CommonProps & {
	type: Extract<PersonType, 'RECRUITER'>;
	active: boolean;
	companyId: string;
};

export type ApiUser = AdminUser | StudentUser | RecruiterUser;

export const isAdminUser = (user?: ApiUser): user is AdminUser =>
	user?.type === 'ADMIN' || user?.type === 'SUPERADMIN';

export const isStudentUser = (user?: ApiUser): user is StudentUser =>
	user?.type === 'STUDENT' || user?.type === 'ALUMNUS';

export const isRecruiterUser = (user?: ApiUser): user is RecruiterUser =>
	user?.type === 'RECRUITER';

export const decodeToken = (token: string) => {
	let decodedToken;
	// Decode token
	try {
		decodedToken = decodeJWT(token) as ApiUser;
	} catch (error) {
		decodedToken = null;
	}

	return decodedToken;
};

export const validateTokenExp = (user: ApiUser | null) => {
	if (user !== null && typeof user.exp === 'number') {
		// If exp date is before now, its invalid
		return isBefore(new Date(), new Date(user.exp * 1000));
	}
	// If exp date is not a number, its invalid
	return false;
};

export const renewToken = async (
	handleNewToken: (newToken: string) => void,
) => {
	const newToken = await api().get('login/jwt').text();
	handleNewToken(newToken);
	Store.set(Store.keys.Token, newToken);
};
