import * as Yup from 'yup';

import { isCountryCzechRepublic } from 'utils/UASHelpers';

import { shortStringSchema } from './validation';

import { Country, Municipality, Region, vsb } from 'typing/endpoints';

export const ADDRESS_INIT_VALUE: vsb.Address = {
	country: (null as unknown) as Country,
	municipality: (null as unknown) as Municipality,
	municipalityForeign: '',
	region: (null as unknown) as Region,
	regionForeign: '',
	fullAddress: (undefined as unknown) as string,
	id: (undefined as unknown) as string,
	street: '',
	zipCode: '',
	descriptiveNumber: '',
	orientationNumber: '',
	order: 0,
};
export const ADDRESSES_INIT_VALUE: vsb.Address[] = [ADDRESS_INIT_VALUE];

export const companyAddressValidationSchema = Yup.object({
	country: Yup.object().nullable().required('Požadované'),
	municipality: Yup.object()
		.nullable()
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.object().required('Požadované'),
		}),
	municipalityForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
	region: Yup.object()
		.nullable()
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.object().required('Požadované'),
		}),
	regionForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
	street: shortStringSchema,
	zipCode: Yup.string()
		.required('Požadované')
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.string().matches(/\d{3} ?\d{2}/, 'Nesprávný formát'),
		}),
	descriptiveNumber: Yup.string().matches(/\d+/, 'Musí být číslo'),
	orientationNumber: Yup.string()
		.matches(/\d+/, 'Musí být číslo')
		.required('Požadované'),
});

export const addressValidationSchema = Yup.object({
	country: Yup.object().nullable().required('Požadované'),
	municipality: Yup.object()
		.nullable()
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.object().required('Požadované'),
		}),
	region: Yup.object()
		.nullable()
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.object().required('Požadované'),
		}),
	municipalityForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
	regionForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
});

export const offerAddressValidationSchema = Yup.object({
	country: Yup.object().nullable().required('Požadované'),
	municipality: Yup.object().nullable(),
	region: Yup.object()
		.nullable()
		.when('country', {
			is: isCountryCzechRepublic,
			then: Yup.object().required('Požadované'),
		}),
	municipalityForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
	regionForeign: Yup.string().when('country', {
		is: c => !isCountryCzechRepublic(c),
		then: shortStringSchema,
	}),
});

export const municipalityLabelWithRegion = (m: Municipality | null) =>
	m ? `${m.name}, ${m.region.name}` : '';

export const getAddressLabel = (address: vsb.Address) =>
	isCountryCzechRepublic(address?.country)
		? address?.region?.name ?? address?.country?.name ?? ''
		: `${address?.country?.name}${
				address?.municipalityForeign ? `, ${address?.municipalityForeign}` : ''
		  }`;
export const getMunicipality = (address: vsb.Address) => {
	if (isCountryCzechRepublic(address.country)) {
		const municipality = address?.municipality?.name ?? '';
		const region = address?.region?.name ?? '';
		if (municipality && region) {
			return `${region}: ${municipality}`;
		}
		return municipality ? municipality : region;
	} else {
		return `${address.country?.name}${
			address?.municipalityForeign ? `, ${address?.municipalityForeign}` : ''
		}`;
	}

	return '';
};

export const getFullAddressLabel = (address: vsb.Address) => {
	if (!address) {
		return '';
	}
	console.log({ address });
	let result = '';
	if (isCountryCzechRepublic(address.country)) {
		result =
			address?.street ??
			'' + ' ' + address?.zipCode ??
			'' + ', ' + getAddressLabel(address);
		return result;
	} else {
		return 'Zahranicie';
	}
};
