import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/header';
import Footer from 'components/footer';
import { Flex } from 'components/styled';
import AppRoutes from 'components/routing/AppRoutes';
import UnsupportedBrowserWarning from 'components/error/UnsupportedBrowserWarning';
import { LazyRoute } from 'components/routing/LazyRoute';
import CookiesDialog, {
	useCookiesSettings,
} from 'components/modal/CookiesDialog';
import StudentBottomMenu from 'components/footer/StudentBottomMenu';

import { TutorialCompanyProfileProvider } from 'modules/tutorials/company/profile/_provider';
import { THRESHOLD_TO_SHOW_INTRO_FORM } from 'modules/student/introductionForm';

import { useUserProvider } from 'auth';
import { ThemeProvider } from 'theme';

import { getUserRoutes, STUDENT_GDPR_ROUTE_NAME } from 'auth/routes';
import { getUserHeaderItems } from 'auth/headerMenu';
import Lazy from 'auth/lazyComps';
import { isStudentUser } from 'auth/token';

import { ScrollDirectionContextProvider } from 'hooks/useScrollDirection';

import { isValidBrowser } from 'utils/checkBrowserVersion';

import ToastifyStyles from 'theme/ToastifyStyles';
import GlobalStyles from 'theme/GlobalStyles';

const queryCache = new QueryCache();

const App: React.FC = () => {
	// Token renewal
	const { token, Provider: UserContextProvider } = useUserProvider();
	const routes = useMemo(() => getUserRoutes(token), [token]);
	const headerItems = useMemo(() => getUserHeaderItems(token), [token]);
	const cookiesSettings = useCookiesSettings();

	useEffect(() => {
		if (
			process.env.NODE_ENV === 'production' &&
			window.location.hostname.includes('kportal.vsb.cz')
		) {
			if (cookiesSettings?.gAnalytics) {
				// init GA4 production tracker
				ReactGA4.initialize('G-7DL76XVLNN');
				ReactGA4.send('pageview');

				// init GA universal production tracker
				ReactGA.initialize('UA-13024342-41');
			}
			if (cookiesSettings?.fbPixel) {
				// init FB pixel production tracker
				ReactPixel.init('2313940585581765');
				ReactPixel.pageView();
			}
		} else {
			/*  debugging */
			if (cookiesSettings?.gAnalytics) {
				ReactGA.initialize('UA-187230124-1');
				ReactGA4.initialize('G-D8ZPLXP1FN');
				ReactGA4.send('pageview');
			}
		}
	}, [cookiesSettings]);
	return (
		<>
			{!isValidBrowser && <UnsupportedBrowserWarning />}
			<ThemeProvider>
				<ErrorBoundary>
					<ReactQueryCacheProvider queryCache={queryCache}>
						<Flex as="main" flexDirection="column" minHeight="100vh">
							<Router>
								<UserContextProvider value={token}>
									<TutorialCompanyProfileProvider>
										<GlobalStyles />
										<ToastifyStyles />
										<ScrollDirectionContextProvider>
											<Header user={token} {...headerItems} />
											<AppRoutes
												routes={routes}
												forcedRedirect={
													isStudentUser(token) &&
													(token?.gdpr === null || token?.gdpr === false)
														? STUDENT_GDPR_ROUTE_NAME
														: undefined
												}
												forceProfileEdit={
													isStudentUser(token) &&
													token?.gdpr === true &&
													(token.profileCompleteness === null ||
														token.profileCompleteness === undefined ||
														token.profileCompleteness <
															THRESHOLD_TO_SHOW_INTRO_FORM)
														? '/introduction-form'
														: undefined
												}
												notFound={LazyRoute(Lazy.NotFound)}
											/>
											<Footer />
											<ToastContainer
												position="bottom-center"
												newestOnTop={false}
												closeOnClick
												draggable
												pauseOnHover
												transition={Slide}
												autoClose={false}
											/>
											<StudentBottomMenu
												user={token}
												collapseWidth={headerItems.collapseWidth}
											/>
										</ScrollDirectionContextProvider>
									</TutorialCompanyProfileProvider>
								</UserContextProvider>
							</Router>
						</Flex>
						<ReactQueryDevtools />
						<CookiesDialog />
					</ReactQueryCacheProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</>
	);
};

export default App;
