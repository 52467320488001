import ky from 'ky';

import store from 'utils/Store';

type ApiOptions = {
	skipToken?: boolean;
};

export const api = (opts: ApiOptions = {}) => {
	const skipToken = Boolean(opts?.skipToken); // default: false

	return ky.extend({
		prefixUrl: '/api',
		timeout: 30000,
		hooks: {
			beforeRequest: [
				request => {
					if (skipToken) {
						return;
					}
					// If we have token, use it in request
					const token = store.get<string>(store.keys.Token, undefined);

					if (!token) {
						return new Response(null, {
							status: 403,
							statusText: 'Forbidden',
						});
					}
					request.headers.set('Authorization', `Bearer ${token}`);
					return;
				},
			],
			afterResponse: [
				(_request, _options, response) => {
					// If response contains bearer, save it as token
					if (response.headers.has('bearer')) {
						store.set(store.keys.Token, response.headers.get('bearer'));
					}
					// If unauthorized token, remove it
					if (response.status === 401) {
						store.remove(store.keys.Token);
					}
				},
			],
		},
	});
};
