/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { Fragment, ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import { Box, Flex, Grid } from 'components/styled';
import { ResponsivePadder } from 'components/styled/Wrapper';
import Text, { OffscreenText } from 'components/styled/Text';
import { HrefLink, NavLink } from 'components/styled/Link';

import RenderAssignedPopupWindow from 'modules/popups';

import { Theme } from 'theme';
import { KCLogoIcon } from 'assets';

import { ApiUser, isStudentUser } from 'auth/token';

import { useMobileView } from 'hooks/useBreakpoint';
import { useScrollDirection } from 'hooks/useScrollDirection';

import { getEntryRoute } from 'utils/UASHelpers';
import {
	EMBED_URL,
	POPUP_REDICERT_PATH,
	FORCED_PROFILE_EDIT_FORM,
} from 'utils/enumMaps';

import { ReactComponent as HeaderLogo } from './header-logo.svg';
import NotificationsBadge from './NotificationsBadge';
import SideMenu from './SideMenu';
import SideMenuStudent from './SideMenuStudent';
import UserBadge from './UserBadge';
import SideNotificationsMenu from './SideNotificationsMenu';

export type HeaderItem = {
	title: string;
	to: LinkProps<unknown>['to'];
};

export type UserBadgeItem = {
	title: string;
	icon?: ReactNode;
	to: string;
};

export type HeaderProps = {
	user?: ApiUser;
	items: HeaderItem[];
	badgeItems: UserBadgeItem[];
	collapseWidth: string;
};

export const HEADER_WRAPPER_ID = 'page-header';

const Header: React.FC<HeaderProps> = ({
	user,
	badgeItems,
	items,
	collapseWidth,
}) => {
	const { pathname } = useLocation();
	const isMobile = useMobileView();
	const { isVisible } = useScrollDirection();

	// hide header if embed offers are used
	if (pathname === EMBED_URL) {
		return <></>;
	}

	if (pathname.includes(POPUP_REDICERT_PATH)) {
		return <></>;
	}

	if (pathname === FORCED_PROFILE_EDIT_FORM) {
		return <></>;
	}

	const isStudent = isStudentUser(user);

	return (
		<Box
			bg="white"
			position="sticky"
			//position={isStudent && isMobile ? 'sticky' : 'sticky'}
			top={0}
			// eslint-disable-next-line no-nested-ternary
			zIndex={isStudent && isMobile ? (isVisible ? 11 : -1) : 11}
			// eslint-disable-next-line no-nested-ternary
			opacity={isStudent && isMobile ? (isVisible ? 1 : 0) : 1}
			boxShadow={
				isStudent && isMobile ? '0px 0px 4px 4px rgba(0,0,0,0.1)' : undefined
			}
		>
			{/* {isStudent && <StudentAfterLoginDialog />} */}
			{user && <RenderAssignedPopupWindow />}
			<ResponsivePadder
				id={HEADER_WRAPPER_ID}
				as="header"
				py={3}
				justifyContent="space-between"
				alignItems="center"
				css={(theme: Theme) => css`
					display: grid;
					grid-gap: ${theme.space[3]}px;
					width: 100%;
					justify-content: space-between;

					/* Collapsed */
					@media (max-width: calc(${collapseWidth} - 1px)) {
						grid-template-areas: 'side-menu logo notifications-menu';
						grid-template-columns: max-content 1fr max-content;
						justify-items: center;
					}

					/* Expanded */
					@media (min-width: ${collapseWidth}) {
						${user
							? css`
									grid-template-areas: 'unilogo logo links notifications user';
									grid-template-columns: max-content 1fr max-content max-content;
							  `
							: css`
									grid-template-areas: 'unilogo logo  text-right';
									grid-template-columns: max-content 1fr max-content;
							  `}
					}
				`}
			>
				<OffscreenText as="h1">Kariérní portál VŠB - TUO</OffscreenText>

				{user ? (
					<Flex alignItems="center">
						{/* KC Logo */}

						{isStudentUser(user) && (
							<Flex
								// as={p => (
								// 	<HrefLink
								// 		href="https://www.vsb.cz/kariernicentrum/cs"
								// 		target="_blank"
								// 		{...p}
								// 	/>
								// )}
								as={p => <Link to={getEntryRoute(user?.type)} {...p} />}
								gridArea="unilogo"
								minWidth="160px"
								px={2}
								py={3}
								css={(theme: Theme) => css`
									/* margin: -${theme.space[2]}px -${theme.space[3]}px; */
									@media (max-width: calc(${collapseWidth} - 1px)) {
										display: none;
									}
								`}
							>
								<KCLogoIcon height={35} />
							</Flex>
						)}

						{/* Portal Logo */}
						<Flex
							as={p => <Link to={getEntryRoute(user?.type)} {...p} />}
							gridArea="logo"
							px={2}
							py={3}
							justifyContent={user ? 'flex-start' : 'center'}
							css={(theme: Theme) => css`
								margin-top: -${theme.space[3]}px;
								margin-bottom: -${theme.space[3]}px;
							`}
						>
							<HeaderLogo height={30} />
						</Flex>
					</Flex>
				) : (
					<>
						<Flex
							as={p => (
								<HrefLink
									href="https://www.vsb.cz/kariernicentrum/cs"
									target="_blank"
									{...p}
								/>
							)}
							gridArea="unilogo"
							minWidth="200px"
							px={2}
							py={3}
							css={(theme: Theme) => css`
								/* margin: -${theme.space[2]}px -${theme.space[3]}px; */
								@media (max-width: calc(${collapseWidth} - 1px)) {
									display: none;
								}
							`}
						>
							<KCLogoIcon height={35} />
						</Flex>

						{/* Portal Logo */}
						<Flex
							as={p => <Link to={'/'} {...p} />}
							gridArea="logo"
							px={2}
							py={3}
							justifyContent={user ? 'flex-start' : 'center'}
							css={(theme: Theme) => css`
								margin-top: -${theme.space[3]}px;
								margin-bottom: -${theme.space[3]}px;
							`}
						>
							<HeaderLogo height={30} />
						</Flex>
					</>
				)}

				{user ? (
					<Fragment>
						{/* Links */}
						<Grid
							gridArea="links"
							gridGap={2}
							gridAutoFlow="column"
							gridAutoColumns="max-content"
							justifyContent="flex-end"
							css={css`
								@media (max-width: calc(${collapseWidth} - 1px)) {
									display: none;
								}
							`}
						>
							{items.map((item, index) => (
								<NavLink
									key={`header-nav-item-${item.title}-${index}`}
									to={item.to}
									p={3}
								>
									{item.title}
								</NavLink>
							))}
						</Grid>

						{/* Side menu */}
						<Box
							color="primary"
							gridArea="side-menu"
							css={css`
								@media (min-width: ${collapseWidth}) {
									display: none;
								}
							`}
						>
							{isStudentUser(user) ? (
								<SideMenuStudent
									user={user}
									badgeItems={badgeItems}
									items={items}
								/>
							) : (
								<SideMenu user={user} badgeItems={badgeItems} items={items} />
							)}
						</Box>

						{/* Notifications */}
						<Flex
							alignItems="center"
							flexShrink={0}
							gridArea="notifications"
							css={css`
								@media (max-width: calc(${collapseWidth} - 1px)) {
									display: none;
								}
							`}
						>
							<NotificationsBadge />
						</Flex>

						{/* Notifications collapsed */}
						<Flex
							alignItems="center"
							flexShrink={0}
							gridArea="notifications-menu"
							css={css`
								@media (min-width: ${collapseWidth}) {
									display: none;
								}
							`}
						>
							<SideNotificationsMenu />
						</Flex>

						{/* User */}
						<Flex
							alignItems="center"
							flexShrink={0}
							gridArea="user"
							css={css`
								@media (max-width: calc(${collapseWidth} - 1px)) {
									display: none;
								}
							`}
						>
							<UserBadge user={user} badgeItems={badgeItems} />
						</Flex>
					</Fragment>
				) : (
					<Flex
						justifySelf="flex-end"
						gridArea="text-right"
						css={css`
							@media (max-width: calc(${collapseWidth} - 1px)) {
								display: none;
							}
						`}
					>
						<Text textAlign="center" fontSize="sm">
							<b>Oficiální kariérní portál</b>
							<br />
							VŠB - Technické univerzity Ostrava
						</Text>
					</Flex>
				)}
			</ResponsivePadder>
		</Box>
	);
};

export default Header;
