import React, { FC } from 'react';

import { Wrapper } from 'components/styled/Wrapper';
import Text from 'components/styled/Text';
import { NavHrefButton } from 'components/styled/Button';
import EventCalendar from 'components/form/input/DateInput/EventCalendar';
import { Box, Flex } from 'components/styled';

import PersonInfo from './PersonInfo';

type CartProps = {
	title: string;
	paragraphs: string[];
	buttonLabel?: string;
	buttonLink?: string;
	endTitle?: string;
	calendar?: boolean;
	sideMenuTitle?: string;
};

const CartPanel: FC<CartProps> = ({
	title,
	paragraphs,
	buttonLabel,
	buttonLink,
	endTitle,
	calendar,
}) => {
	return (
		<Flex
			flexDirection="column"
			boxShadow="2px 2px 6px 2px rgb(0 0 0 / 7%)"
			textAlign="center"
			px={20}
			py={4}
			bg="white"
			my={3}
		>
			<Text mt={0} fontWeight="bold" fontSize="md">
				{title}
			</Text>
			{calendar ? (
				<EventCalendar />
			) : (
				<>
					{paragraphs.map((p, index) => (
						<Box key={`p-${index}`} my={3}>
							{p.split('\n').map((line, lineIndex) => (
								<Text
									my={0}
									key={`line-${index}-${lineIndex}-${line}`}
									fontSize={14}
								>
									{line}
								</Text>
							))}
						</Box>
					))}

					{buttonLink && buttonLabel && (
						<NavHrefButton mt={3} py={2} href={buttonLink} target="_blank">
							{buttonLabel.toUpperCase()}
						</NavHrefButton>
					)}
					{endTitle && <Text fontWeight="bold">{endTitle}</Text>}
				</>
			)}
		</Flex>
	);
};

export const carts: CartProps[] = [
	{
		sideMenuTitle: 'Kariérní konzultace',
		title: 'Potřebuješ poradit?',
		buttonLabel: 'Chci konzultaci',
		buttonLink:
			'https://kariernicentrum.vsb.cz/cs/konzultace-a-rozvoj/individualni-konzultace',
		paragraphs: [
			'Využij individuální konzultace,\n které pomohou nastartovat tvou kariéru. Přihlas se ještě dnes!',
		],
	},
	{
		sideMenuTitle: 'Pohovor nanečisto',
		title: 'Čeká tě pohovor?',
		buttonLabel: 'CHCI POHOVOR NANEČISTO',
		buttonLink:
			'https://kariernicentrum.vsb.cz/cs/konzultace-a-rozvoj/pohovor-nanecisto',
		paragraphs: [
			'Naše simulace reálného pohovoru \nti pomůže se lépe připravit\na získat cennou zpětnou vazbu.',
		],
	},
	{
		sideMenuTitle: 'Průvodce kariérním portfoliem',
		title: 'Je libo příprava od A do Z?',
		buttonLabel: 'VÍCE O PRŮVODCI',
		buttonLink: 'https://kariernicentrum.vsb.cz/cs/pruvodce',
		paragraphs: [
			//'Absolvujte Průvodce kariérním\nportfoliem, který vás provede vším\npotřebným pro vstup na trh práce.',
			'Tak to si projdi našeho průvodce, \nkterý ti pomůže od nástupu na univerzitu \naž po nastartovaní tvé kariéry.',
		],
	},
	{
		title: 'Kalendář akcí',
		paragraphs: [],
		calendar: true,
	},
	{
		sideMenuTitle: 'Všechny služby kariérního centra',
		title: 'Jsme Kariérní centrum VŠB-TUO!',
		buttonLabel: 'VŠECHNY NAŠE SLUŽBY',
		buttonLink: 'https://www.vsb.cz/kariernicentrum/cs/pro-studenty/',
		paragraphs: [
			'Pomáháme studentům/absolventům \n VŠB-TUO s rozjezdem jejich kariéry \n v zaměstnání i podnikání.',
			'Pořádáme workshopy, rozvojové \n programy, individuální konzultace \n i akce se zaměstnavateli.',
		],
	},
	{
		title: 'Víš, že...',
		endTitle: 'KC | Plus pro vaši kariéru.',
		paragraphs: [
			'pouze 10 % studentů se zajímá\no svou budoucí kariéru a podniká\nv tom kroky už za svého studia? ',
			'Ti jsou však na konci svého studia\npro zaměstnavatele nesrovnatelně\natraktivnější a zkušenější',
		],
	},
];

const StudentDashboardLeftPanel = () => (
	<Wrapper px={2}>
		<PersonInfo />
		{carts.map(c => (
			<CartPanel key={c.title} {...c} />
		))}
	</Wrapper>
);

export default StudentDashboardLeftPanel;
