/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, useState, useCallback, Fragment } from 'react';
import { MdMoreHoriz, MdClose } from 'react-icons/md';

import MainButton from 'components/floatingactions/MainButton';
import FloatingMenu from 'components/floatingactions/FloatingMenu';
import { Box } from 'components/styled';

import useClickaway from 'hooks/useClickaway';

type Props = {
	id: string;
};

const FloatingActionsButton: FC<Props> = ({ id, children }) => {
	// Actions
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = useCallback(() => setIsOpen(open => !open), [setIsOpen]);
	const [clickawayRef] = useClickaway<HTMLDivElement>(
		() => isOpen && setIsOpen(false),
	);

	return (
		<Box ref={clickawayRef} position="relative">
			<FloatingMenu
				id={id}
				slideSpeed={500}
				direction="up"
				spacing={8}
				isOpen={isOpen}
				css={css`
					position: absolute;
					bottom: 0;
					right: 0;
				`}
			>
				<MainButton
					iconResting={<MdMoreHoriz size={15} color="white" />}
					iconActive={<MdClose size={15} color="white" />}
					backgroundColor="secondary"
					onClick={toggleOpen}
					size={35}
				/>
				<Fragment>{children}</Fragment>
			</FloatingMenu>
		</Box>
	);
};
export default FloatingActionsButton;
