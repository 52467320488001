import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import {
	Redirect,
	Route,
	RouteProps,
	Switch,
	useLocation,
} from 'react-router-dom';

import { RouterItem } from './LazyRoute';

type Props = {
	routes: RouterItem[];
	forcedRedirect?: string;
	forceProfileEdit?: string;
	notFound: RouteProps['render'];
};

const AppRoutes: React.FC<Props> = ({
	routes,
	forcedRedirect,
	forceProfileEdit,
	notFound,
}) => {
	// Location
	const { pathname, search } = useLocation();

	//exclude /offer detail from pageview, because it has its own pageview function call
	useEffect(() => {
		if (
			pathname.slice(0, 6) !== '/offer' &&
			process.env.NODE_ENV === 'production'
		) {
			ReactGA.pageview(pathname + search);
		}
		window.scrollTo({ top: 0 });
	}, [pathname, search]);

	return (
		<Switch>
			{!!forcedRedirect && pathname !== forcedRedirect && (
				<Redirect from="*" to={forcedRedirect} />
			)}
			{!!forceProfileEdit && pathname !== forceProfileEdit && (
				<Redirect from="*" to={forceProfileEdit} />
			)}

			{routes.map(route => {
				if (route.type === 'route') {
					return (
						<Route
							key={`app-route-${route.path}`}
							exact
							path={route.path}
							render={route.render}
						/>
					);
				} else if (route.type === 'redirect') {
					return (
						<Route key={`app-redirect-${route.path}`} exact path={route.path}>
							<Redirect to={route.to} />
						</Route>
					);
				} else {
					throw new Error(`Unhandled route type ${route}`);
				}
			})}

			{/* Not found route */}
			<Route exact render={notFound} />
		</Switch>
	);
};

export default AppRoutes;
