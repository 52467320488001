import { format } from 'date-fns';

import { useCompanyEventsPaginated } from 'api/companyEventsApi';

import { UASParams } from 'utils/UASTypes';

const useCalendarCompanyEvents = (to: Date) => {
	const params: UASParams = {
		page: 0,
		pageSize: -1,
		filter: [
			{
				field: 'to',
				operation: 'LTE',
				value: to.toISOString(),
			},
		],
	};

	return useCompanyEventsPaginated(params);
};

export const useCalendarCompanyEventsOnSelectedDay = (day: Date) => {
	const min = new Date(day);
	const max = new Date(day);
	min.setHours(0, 0, 0, 0);
	max.setHours(23, 59, 0, 0);
	const params: UASParams = {
		page: 0,
		pageSize: -1,
		filter: [
			{
				operation: 'AND',
				filter: [
					{
						field: 'from',
						operation: 'LTE',
						//value: format(min, "yyyy-MM-dd'T'HH:mm:ss"),
						value: format(min, 'yyyy-MM-dd'),
					},
					{
						field: 'to',
						operation: 'GTE',
						//value: format(max, "yyyy-MM-dd'T'HH:mm:ss"),
						value: format(max, 'yyyy-MM-dd'),
					},
				],
			},
		],
	};

	return useCompanyEventsPaginated(params);
};

export default useCalendarCompanyEvents;
