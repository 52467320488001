import * as Yup from 'yup';
import { decode } from 'html-entities';

import { isRichTextEmpty } from 'components/form/richtext/RichTextInput';

// Source: https://www.zizka.ch/pages/programming/ruzne/rodne-cislo-identifikacni-cislo-rc-ico-kontrola-validace.html#toc-ic-javascript
export const validateIco = (x?: string | null, validIfEmpty = false) => {
	if (!x) {
		return validIfEmpty;
	}

	try {
		let a = 0;
		if (x.length === 0) {
			return validIfEmpty;
		}
		if (x.length !== 8) {
			throw new Error('INVALID_IC_LENGTH');
		}

		const b = x.split('');
		let c = 0;

		for (let i = 0; i < 7; i++) {
			a += parseInt(b[i]) * (8 - i);
		}

		a = a % 11;
		c = 11 - a;

		if (a === 1) {
			c = 0;
		}
		if (a === 0) {
			c = 1;
		}
		if (a === 10) {
			c = 1;
		}

		if (parseInt(b[7]) !== c) {
			throw new Error('INVALID_IC_CONTENT');
		}
	} catch (e) {
		return false;
	}

	return true;
};

// Source: https://www.zizka.ch/pages/programming/ruzne/rodne-cislo-identifikacni-cislo-rc-ico-kontrola-validace.html#toc-rodne-cislo-javascript
export const validateRc = (x: string, xAge: number, validIfEmpty = false) => {
	let age = xAge;

	if (!xAge) {
		age = 0;
	}

	try {
		if (x.length === 0) {
			return validIfEmpty;
		}

		if (x.length < 9) {
			throw new Error('INVALID_RC_LENGTH');
		}

		let year = parseInt(x.substr(0, 2), 10);
		let month = parseInt(x.substr(2, 2), 10);
		const day = parseInt(x.substr(4, 2), 10);

		if (x.length === 9 && year < 54) {
			return true;
		}

		let c = 0;
		if (x.length === 10) {
			c = parseInt(x.substr(9, 1));
		}

		let m = parseInt(x.substr(0, 9)) % 11;
		if (m === 10) {
			m = 0;
		}
		if (m !== c) {
			throw new Error('INVALID_RC_LCONTENT');
		}

		year += year < 54 ? 2000 : 1900;
		if (month > 70 && year > 2003) {
			month -= 70;
		} else if (month > 50) {
			month -= 50;
		} else if (month > 20 && year > 2003) {
			month -= 20;
		}

		const d = new Date();
		if (year + age > d.getFullYear()) {
			throw new Error('INVALID_RC_LCONTENT');
		}
		if (month === 0) {
			throw new Error('INVALID_RC_LCONTENT');
		}
		if (month > 12) {
			throw new Error('INVALID_RC_LCONTENT');
		}
		if (day === 0) {
			throw new Error('INVALID_RC_LCONTENT');
		}
		if (day > 31) {
			throw new Error('INVALID_RC_LCONTENT');
		}
	} catch (e) {
		return false;
	}
	return true;
};

// Source: https://www.zizka.ch/pages/programming/ruzne/rodne-cislo-identifikacni-cislo-rc-ico-kontrola-validace.html#toc-dic-javascript
export const validateDic = (x?: string | null, validIfEmpty = false) => {
	if (!x) {
		return validIfEmpty;
	}

	try {
		if (x.length === 0) {
			return validIfEmpty;
		}

		const id = x.substr(0, 2).toUpperCase();
		x = x.substr(2);

		if (id === 'CZ' || id === 'SK') {
			if (x.length < 8) {
				throw new Error('INVALID_IC_LENGTH');
			} else if (x.length > 11) {
				throw new Error('INVALID_IC_LENGTH');
			} else {
				return true;
			}
		}
		return validateRc(x, 18, validIfEmpty);
	} catch (e) {
		return false;
	}
};

export const passwordValidationMessage =
	'Heslo musí mít minimálně 10 znaků a musí mít alespoň 1 velké písmeno, 1 číslo a 1 malé písmeno';

export const passwordValidationSchema = Yup.string().matches(
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
	passwordValidationMessage,
);

export const phoneValidationSchema = Yup.string().matches(
	/^((00|\+)\d{1,3} ?)?(\d ?){9}$/,
	'Nesprávný formát telefonního čísla',
);

export const requiredRichTextSchema = Yup.string().test(
	'requiredRichTextSchema',
	'Požadované',
	value => (value ? !isRichTextEmpty(value) : false),
);

export const maxCharMessage = (max: number) =>
	`Může obsahovat maximálně ${max} znaků`;

export const shortStringSchema = Yup.string()
	.trim()
	.max(100, maxCharMessage(100));

export const mediumStringSchema = Yup.string()
	.trim()
	.max(500, maxCharMessage(500));
export const longStringSchema = Yup.string()
	.trim()
	.max(2000, maxCharMessage(2000));

export const richTextMaxCharCount = (max: number) =>
	Yup.string().test('richTextMaxCharCount', maxCharMessage(max), value =>
		value ? decode(value).replace(/<[^>]*>/g, '').length < max : true,
	);

// Merge Yup schemas
// Source: https://github.com/jquense/yup/issues/232#issuecomment-499789569

export function mergeSchemas(...schemas: Yup.ObjectSchema[]) {
	const [first, ...rest] = schemas;

	const merged = rest.reduce(
		(mergedSchemas, schema) => mergedSchemas.concat(schema),
		first,
	);

	return merged;
}

export const facebookUrlValidationSchema = shortStringSchema.matches(
	/^((https):\/\/)(www[.])(facebook).com\/.+$/i,
	'Adresa musí být ve formátu https://www.facebook.com/<Váš účet>',
);
export const instagramUrlValidationSchema = shortStringSchema.matches(
	/^((https):\/\/)(www[.])(instagram).com\/.+$/i,
	'Adresa musí být ve formátu https://www.instagram.com/<Váš účet>',
);
export const linkedInUrlValidationSchema = shortStringSchema.matches(
	/^((https):\/\/)(www[.])(linkedin).com\/.+$/i,
	'Adresa musí být ve formátu https://www.linkedin.com/<Váš účet>',
);
export const twitterUrlValidationSchema = shortStringSchema.matches(
	/^((http|https):\/\/)?(www[.])?(twitter).com\/.+$/i,
	'url',
);
