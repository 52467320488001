import { useCallback, useState } from 'react';

const useSlideToSide = () => {
	const [xDown, setXDown] = useState(0);
	const [yDown, setYDown] = useState(0);
	const [leftSwipe, setLeftSwipe] = useState(false);

	const handleTouchStart = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) => {
			const firstTouch = e.touches[0];
			setXDown(firstTouch.clientX);
			setYDown(firstTouch.clientY);
			setLeftSwipe(false);
		},
		[],
	);

	const handleTouchMove = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) => {
			if (!xDown || !yDown) {
				return;
			}

			const xUp = e.touches[0].clientX;
			const yUp = e.touches[0].clientY;

			const xDiff = xDown - xUp;
			const yDiff = yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				/*most significant*/
				if (xDiff > 5) {
					setLeftSwipe(true);
					setXDown(0);
					setYDown(0);
				} else {
					setXDown(0);
				}
			}
		},
		[xDown, yDown],
	);
	return { handleTouchStart, handleTouchMove, leftSwipe };
};

export default useSlideToSide;
