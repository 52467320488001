import { useUserToken } from 'auth';

import { useLoggedInCompany } from 'api/companyApi';

import { isAdminUser } from 'auth/token';

type Variant = 'offer' | 'event';

const useOfferActivate = (variant?: Variant) => {
	// Token
	const token = useUserToken();
	const item = variant === 'event' ? 'akce' : 'nabídky';

	// Company status
	const companyResponse = useLoggedInCompany();
	const offersCount = companyResponse.data?.offersCount ?? 0;
	const unlimitedOffers = !!companyResponse.data?.hasUnlimitedOffers;
	const outOfOffers = !unlimitedOffers && offersCount <= 0;
	const isCompanyActive = companyResponse.data?.isActive;

	return [
		!isAdminUser(token) && (!isCompanyActive || outOfOffers),
		// eslint-disable-next-line no-nested-ternary
		isAdminUser(token)
			? undefined
			: // eslint-disable-next-line no-nested-ternary
			!isCompanyActive
			? `Pro zveřejnění ${item} musí být nejprve váš účet scvhálen správcem.`
			: outOfOffers
			? 'Nemáte dostatek zveřejnění.'
			: undefined,
		companyResponse.data,
	] as const;
};

export const useHasEnoughCredits = (price: number) => {
	// Token
	const token = useUserToken();

	// Company status
	const companyResponse = useLoggedInCompany();
	const offersCount = companyResponse.data?.offersCount ?? 0;
	const unlimitedOffers = !!companyResponse.data?.hasUnlimitedOffers;
	const outOfCredits = !unlimitedOffers && offersCount < price;
	const isCompanyActive = companyResponse.data?.isActive;

	return {
		notEnough: !isAdminUser(token) && (!isCompanyActive || outOfCredits),
		credits: unlimitedOffers ? -1 : offersCount,
		// eslint-disable-next-line no-nested-ternary
		title: isAdminUser(token)
			? undefined
			: // eslint-disable-next-line no-nested-ternary
			!isCompanyActive
			? `Pro zveřejnění akce musí být nejprve váš účet scvhálen správcem.`
			: outOfCredits
			? 'Nemáte dostatek zveřejnění.'
			: undefined,
		company: companyResponse.data,
	};
};

export default useOfferActivate;
