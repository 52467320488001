import { ResponsePromise } from 'ky';
import normalizeUtility from 'normalize-url';

export const CzechCountryId = '4a1b50cf-cefa-426c-9e96-4cbb9a8f6154';

export const getDateString = (date: Date) =>
	(typeof date === 'string' ? new Date(date) : date)?.toLocaleDateString(
		'cs-CZ',
	);

export const getTimeString = (date: Date) =>
	(typeof date === 'string'
		? new Date(date)
		: date
	)?.toLocaleTimeString('cs-CZ', { hour: '2-digit', minute: '2-digit' });

export const getFullName = ({
	firstName,
	lastName,
	titleBefore,
	titleAfter,
}: Record<'firstName' | 'lastName' | 'titleBefore' | 'titleAfter', string>) =>
	[titleBefore, firstName, lastName, titleAfter].join(' ').trim();

export function assert(
	condition: boolean | undefined,
	msg?: string,
): asserts condition {
	if (!condition) {
		throw new Error(msg);
	}
}

export const pluralRules = (
	count: number,
	options: { one: string; few: string; many: string },
) => {
	switch (Math.abs(count)) {
		case 1:
			return `${count} ${options.one}`;
		case 2:
		case 3:
		case 4:
			return `${count} ${options.few}`;
		default:
			return `${count} ${options.many}`;
	}
};

export const responseWithData = <T>(
	response: ResponsePromise,
	getData: (r: Response) => Promise<T> | T = async r => (await r.json()) as T,
) => response.then(async r => [r, await getData(r)] as const);

export const formatCzechCrowns = (price: number) =>
	new Intl.NumberFormat('cs-CZ', {
		style: 'currency',
		currency: 'CZK',
		minimumFractionDigits: 0,
	}).format(price);

export const normalizeUrl = (
	url: string,
	options?: normalizeUtility.Options,
) => {
	try {
		return url
			? normalizeUtility(url, {
					stripWWW: false,
					...options,
			  })
			: '';
	} catch (error) {
		return '';
	}
};

export const asRecord = <T extends unknown>(...obj: T[]): Record<number, T> =>
	obj;

export const retryPromise = <T>(
	fn: () => Promise<T>,
	retriesLeft = 4,
	interval = 250,
) => {
	return new Promise<T>((resolve, reject) => {
		return fn()
			.then(resolve)
			.catch(error => {
				if (retriesLeft === 1) {
					reject(error);
					return;
				}

				setTimeout(() => {
					// Passing on "reject" is the important part
					retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

export type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export const MakeTuple = <T extends string[]>(...args: T) => args;

export const creditsPluralRules = (x: number): string => {
	if (x === 1) {
		return 'kredit';
	}
	if (x < 5) {
		return 'kredity';
	} else {
		return 'kreditů';
	}
};

/**
 *  chrome translator activation check
 * https://stackoverflow.com/questions/4887156/detecting-google-chrome-translation
 */
export const isChromeTranslatorActive = () =>
	document.getElementsByClassName('translated-ltr').length > 0;

export const isDev = () => process.env.NODE_ENV === 'development';
