import {
	ThemeProvider as EmotionThemeProvider,
	useTheme as useEmotionTheme,
} from 'emotion-theming';
import { mergeWith } from 'lodash-es';
import React, { FC } from 'react';

import { DeepPartial } from 'utils';

const theme = {
	colors: {
		primary: '#01ABA2',
		primaryLight: '#EFF9F8',
		secondary: '#1E2838',
		text: '#000000',
		textLight: '#7D7D7D',
		error: '#FB5353',
		success: '#187E16',
		warning: '#EA9634',
		lightGrey: '#F7F7F7',
		darkerGrey: '#B0B0B0',
		lightGreen: '#5BCCC6',
		darkGreen: '#0A8780',
		golden: '#ff9900C7',
	},
	breakpoints: ['40em', '52em', '64em', '76em', '1350px'],
	fontSizes: { sm: 14, md: 16, lg: 20, xl: 24, title: 38 }, //[12, 14, 16, 20, 24, 32, 48, 64, 72],
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export type Theme = typeof theme;
export type PartialTheme = DeepPartial<Theme>;

const useTheme = useEmotionTheme as () => Theme;
export { useTheme };

type Props = {
	themeOverrides?: PartialTheme;
};
export const ThemeProvider: FC<Props> = ({ themeOverrides = {}, children }) => (
	<EmotionThemeProvider theme={mergeWith({}, theme, themeOverrides)}>
		{children}
	</EmotionThemeProvider>
);

export * from './GlobalStyles';
export { default as GlobalStyles } from './GlobalStyles';
export { default as styled } from './styled';
