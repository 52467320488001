import { useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { addDays } from 'date-fns';

import { getCompanyIdByUserType } from 'modules/company/profile/_utils';

import { useUserToken } from 'auth';

import { useCompanyPurchasedProducts } from 'api/purchasedProductsApi';

import { isAdminUser } from 'auth/token';

const dayTime = 24 * 60 * 60 * 1000;

const useLatestCompanyLicence = () => {
	// User logic
	const user = useUserToken();
	const companyId = getCompanyIdByUserType(user);

	// Active license
	const productsResponse = useCompanyPurchasedProducts(companyId);

	const latestLicense = useMemo(
		() =>
			!productsResponse.data
				? undefined
				: sortBy(
						productsResponse.data.filter(
							p => p.product.productType === 'LICENSE' && p.isActive,
						),
						'validTo',
				  )[0],
		[productsResponse.data],
	);

	const daysRemaining = useMemo(() => {
		if (latestLicense) {
			const today = new Date();
			today.setHours(0, 0, 0);
			const validTo = new Date(latestLicense.validTo);
			validTo.setHours(0, 0, 0);
			const invalid = addDays(validTo, 1);
			return Math.ceil((invalid.getTime() - today.getTime()) / dayTime);
		} else {
			return undefined;
		}
	}, [latestLicense]);

	return { latestLicense, daysRemaining };
};

export const useLatestCompanyLicenceAdmin = (companyId: string) => {
	const user = useUserToken();
	const productsResponse = useCompanyPurchasedProducts(
		companyId,
		!isAdminUser(user),
	);

	const latestLicense = useMemo(
		() =>
			!productsResponse.data
				? undefined
				: sortBy(
						productsResponse.data.filter(
							p => p.product.productType === 'LICENSE' && p.isActive,
						),
						'validTo',
				  )[0],
		[productsResponse.data],
	);

	const daysRemaining = useMemo(() => {
		if (latestLicense) {
			const today = new Date();
			today.setHours(0, 0, 0);
			const validTo = new Date(latestLicense.validTo);
			validTo.setHours(0, 0, 0);
			const invalid = addDays(validTo, 1);
			return Math.ceil((invalid.getTime() - today.getTime()) / dayTime);
		} else {
			return undefined;
		}
	}, [latestLicense]);

	return {
		latestLicense,
		daysRemaining,
		isLoading: productsResponse.isLoading,
	};
};
export default useLatestCompanyLicence;
