import { useEffect, useRef } from 'react';

// Idea: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: () => void, delay: number) => {
	const savedCallback = useRef<typeof callback | null>(null);

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		const tick = () => {
			if (savedCallback.current) {
				savedCallback.current();
			}
		};

		const id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay]);
};

export default useInterval;
