import React, { FC, ComponentProps } from 'react';

import styled from 'theme/styled';

import { DIRECTIONS, FloatingMenuContext } from './utils';
import * as T from './_typing';

const StyledMenu = styled.div<T.StyledMenuProps>`
	display: flex;
	width: fit-content;
	margin: 0;
	padding: 0;
	flex-direction: ${p => DIRECTIONS[p.direction]};
	align-items: center;
`;

type Props = ComponentProps<typeof StyledMenu> & T.FloatingMenuProps;

const FloatingMenu: FC<Props> = ({
	id,
	slideSpeed = 500,
	direction = 'down',
	isOpen = false,
	spacing = 8,
	children,
	...rest
}) => {
	return (
		<FloatingMenuContext.Provider
			value={{ isOpen, slideSpeed, direction, spacing }}
		>
			<StyledMenu id={id} direction={direction} {...rest}>
				{children}
			</StyledMenu>
		</FloatingMenuContext.Provider>
	);
};

export default FloatingMenu;
