/** @jsxImportSource @emotion/core */
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Flex } from 'components/styled';

import { useUserProvider } from 'auth';

import { renewToken } from 'auth/token';

import { FormCommonProps, IntroShadowButton } from '.';

const IntroForm5: FC<FormCommonProps> = () => {
	const { replace } = useHistory();
	const { handleNewToken } = useUserProvider();
	return (
		<Flex flexWrap="wrap" width={1} alignItems="center" mb={3}>
			<Box width={1} mt={4} textAlign="center">
				<IntroShadowButton
					onClick={async () => {
						await renewToken(handleNewToken);
						replace('/search/offers');
					}}
					variant="primary"
					width={2 / 3}
					mb={3}
				>
					Prohlédnut nabídky
				</IntroShadowButton>
				<IntroShadowButton
					onClick={async () => {
						await renewToken(handleNewToken);
						replace('/student');
					}}
					variant="outlined"
					bg="white"
					color="black"
					width={2 / 3}
					mb={3}
				>
					Zobrazit můj profil
				</IntroShadowButton>
			</Box>
		</Flex>
	);
};

export default IntroForm5;
