import React, {
	createContext,
	useContext,
	useMemo,
	useState,
	useEffect,
} from 'react';

export type ScrollDirectionContextType = {
	isVisible: boolean;
};

const ScrollDirectionContext = createContext<ScrollDirectionContextType>({
	isVisible: true,
});

export const ScrollDirectionContextProvider: React.FC = ({ children }) => {
	const [isVisible, setIsVisible] = useState(true);
	useEffect(() => {
		const threshold = 0;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			setIsVisible(scrollY > lastScrollY ? false : true);
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [isVisible]);

	const ctx = useMemo(
		() => ({
			isVisible,
		}),
		[isVisible],
	);

	return (
		<ScrollDirectionContext.Provider value={ctx}>
			{children}
		</ScrollDirectionContext.Provider>
	);
};

export const useScrollDirection = () => useContext(ScrollDirectionContext);
