import { useMemo } from 'react';
import {
	InfiniteQueryConfig,
	QueryKey,
	TypedQueryFunction,
	useInfiniteQuery,
} from 'react-query';

import { UASResult } from 'utils/UASTypes';

const useInfiniteUASQuery = <T, Args extends unknown[] = []>(
	queryKey: QueryKey,
	queryFn: TypedQueryFunction<UASResult<T>, [string, ...Args, number?]>,
	options: InfiniteQueryConfig<UASResult<T>> = {},
	pageSize = 10,
) => {
	const result = useInfiniteQuery(queryKey, queryFn, {
		...options,
		getFetchMore: (_prev, all) => {
			if (!all || all.length <= 0) {
				return 0;
			}

			const count = all[0].count;
			const lenght = all.flatMap(i => i.items).length;
			return count > lenght ? lenght / pageSize : undefined;
		},
	});

	const data = useMemo(() => result.data?.flatMap(i => i.items), [result.data]);
	const page = useMemo(() => {
		if (!result.data || result.data.length <= 0) {
			return 0;
		}

		return Math.ceil(result.data.flatMap(i => i.items).length / pageSize);
	}, [result.data, pageSize]);

	return {
		...result,
		data,
		count: result.data?.[0]?.count,
		page,
		hasMore: !!result.canFetchMore,
	};
};
export default useInfiniteUASQuery;
