/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';

import Paper from 'components/styled/Paper';

const CompanyCover: React.FC<{ photo?: string }> = ({ photo, children }) => (
	<Paper
		position="relative"
		alignItems="flex-start"
		height="auto"
		px={0}
		py={0}
		minHeight={photo ? [180, 365] : undefined}
		css={
			photo &&
			css`
				background-image: url(${photo});
				background-size: cover;
				background-position: center;
			`
		}
	>
		{children}
	</Paper>
);

export default CompanyCover;
