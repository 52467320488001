import * as storage from 'store';
import eventsPlugin from 'store/plugins/events';

type Store = typeof storage;

type StoreJsAPI = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	watch(key: string, callback: (value: any) => void): string;
	unwatch(watchId: string): void;
} & Store;

// Store with event plugin types
const store = storage as StoreJsAPI;

// Add events plugin
store.addPlugin(eventsPlugin);

// Methods
const get = <T>(key: string, defaultValue?: T) =>
	store.get(key, defaultValue) as typeof defaultValue;

const set = <T>(key: string, value: T) => store.set(key, value) as T;

const remove = (key: string) => store.remove(key);

const watch = <T>(key: string, callback: (value: T) => void) =>
	store.watch(key, callback);

const unwatch = (watchId: string) => store.unwatch(watchId);

const keys = {
	Token: 'token',
	Redirect: 'redirect',
	TutorialCompanyProfileStep: 'tut-comp-prof-step',
	StatsCountType: 'vsb-stats-count-type',
	StatsViewCollapsed: 'vsb-stats-view',
	StatsPreferredOnly: 'vsb-stats-preferred-only',
	CurrentPopupId: 'vsb-current-popupid',
	PaginationPageSize: 'vsb-pagination-page-size',
};
type StoredWithExpiration<T> = {
	value: T;
	expiration: number;
};
const setWithExpiration = <T>(key: string, value: T, expiresIn: number) => {
	const now = new Date().getTime() / 1000 / 60; //minutes
	const expiration = now + expiresIn;
	set<StoredWithExpiration<T>>(key, { value, expiration });
};

const getWithExpiration = <T>(key: string) => {
	const now = new Date().getTime() / 1000 / 60; //minutes
	const stored = get<StoredWithExpiration<T>>(key);
	const expiresIn = stored?.expiration;
	const value = stored?.value;
	if (!stored || !expiresIn || value === undefined || value === null) {
		remove(key);
		return null;
	}
	if (expiresIn - now <= 0) {
		remove(key);
		return null;
	}

	return value;
};

export default {
	get,
	set,
	remove,
	watch,
	unwatch,
	keys,
	setWithExpiration,
	getWithExpiration,
};
