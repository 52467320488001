import { toast } from 'react-toastify';

const createProgressToast = (initialMessage: string) => {
	const progressToast = toast.info(initialMessage, {
		autoClose: false,
		closeButton: false,
		closeOnClick: false,
	});

	return {
		progressToast,
		infoUpdate: (message: string) =>
			toast.update(progressToast, {
				render: message,
				type: 'info',
			}),
		successUpdate: (message: string) =>
			toast.update(progressToast, {
				render: message,
				type: 'success',
				autoClose: 3000,
				closeButton: true,
				closeOnClick: true,
			}),
		errorUpdate: (message: string) =>
			toast.update(progressToast, {
				render: message,
				type: 'error',
				autoClose: 3000,
				closeButton: true,
				closeOnClick: true,
			}),
	};
};

export default createProgressToast;
