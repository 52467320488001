/** @jsxImportSource @emotion/core */
import React, { FC } from 'react';
import { MdClose, MdHelp, MdHelpOutline } from 'react-icons/md';
import { css, SerializedStyles } from '@emotion/core';

import { Flex } from 'components/styled';
import Button from 'components/styled/Button';
import Paper from 'components/styled/Paper';
import Text from 'components/styled/Text';

import { Theme } from 'theme';

import ModalDialog from '.';

type Props = {
	label: string;
	outLinedIcon?: boolean;
	iconSize?: number;
	title?: string;
	customCss?: (theme: Theme) => SerializedStyles;
};

const HelpDialog: FC<Props> = ({
	label,
	children,
	outLinedIcon = true,
	iconSize = 20,
	title,
	customCss,
}) => (
	<ModalDialog
		label={label}
		control={openModal => (
			<Button variant="text" color="primary" onClick={openModal} p={1}>
				{outLinedIcon ? (
					<MdHelpOutline size={iconSize} />
				) : (
					<MdHelp size={iconSize} />
				)}
			</Button>
		)}
		customCss={customCss}
	>
		{closeModal => (
			<Paper>
				<Flex
					width={1}
					justifyContent={title ? 'space-between' : 'flex-end'}
					alignItems="center"
				>
					{title && (
						<Text fontWeight="bold" fontSize="lg">
							{title}
						</Text>
					)}
					<Button
						variant="text"
						p={2}
						onClick={closeModal}
						css={css`
							&:hover {
								background-color: rgba(0, 0, 0, 0.05);
							}
						`}
					>
						<MdClose size={24} />
					</Button>
				</Flex>
				{children}
			</Paper>
		)}
	</ModalDialog>
);
export default HelpDialog;
