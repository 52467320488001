/** @jsxImportSource @emotion/core */
import React, { useMemo, useState } from 'react';
import { IoMdEye } from 'react-icons/io';
import { css } from '@emotion/core';
import Dialog from '@reach/dialog';
import { MdClose } from 'react-icons/md';

import Button from 'components/styled/Button';
import { Wrapper } from 'components/styled/Wrapper';
import { Box, Flex } from 'components/styled';
import Paper from 'components/styled/Paper';
import Text from 'components/styled/Text';
import RTText from 'components/form/richtext/RTText';

import { Theme } from 'theme';

import { usePopupWindowDetail } from 'api/popupWindowsApi';
import { getFile } from 'api/fileApi';

import { PopUpWindow } from 'typing/endpoints';

const Popup: React.FC<{ id: string; closeModal: () => void }> = ({
	id,
	closeModal,
}) => {
	const response = usePopupWindowDetail(id);
	if (response.isError || response.isLoading) {
		return <></>;
	}
	const popup = response.data;

	if (!popup) {
		return <></>;
	}
	return <RenderPopup closeModal={closeModal} popup={popup} />;
};

export const RenderPopup: React.FC<{
	popup: PopUpWindow;
	closeModal: () => void;
	photoUrl?: string;
}> = ({ popup, closeModal, photoUrl }) => {
	const photo = useMemo(
		() => (photoUrl ?? popup.photo ? getFile(popup.photo) ?? '' : ''),
		[popup.photo, photoUrl],
	);
	const [open, setOpen] = useState<boolean>(true);
	return (
		<Wrapper>
			<Dialog
				isOpen={open}
				onDismiss={() => {
					setOpen(false);
					closeModal();
				}}
				css={(theme: Theme) => css`
					overflow: hidden;
					min-width: 300px;
					max-width: 900px;
					margin: 1vh auto;

					@media (max-width: ${theme.breakpoints[4]}) {
						width: 80% !important;
						min-width: unset;
						padding: 16px;
					}
					@media (max-width: ${theme.breakpoints[3]}) {
						width: 90% !important;
						min-width: unset;
						padding: 16px;
					}
					@media (max-width: ${theme.breakpoints[0]}) {
						width: 100% !important;
						min-width: unset;
						padding: 16px;
					}
				`}
			>
				<Paper
					css={(theme: Theme) => css`
						border: 4px solid ${theme.colors.primary};
						padding: 0;
					`}
				>
					<Flex width={1} alignItems="center" justifyContent="flex-end">
						<Button
							variant="text"
							color="primary"
							p={2}
							onClick={() => {
								setOpen(false);
								closeModal();
							}}
							css={css`
								&:hover {
									background-color: rgba(0, 0, 0, 0.05);
								}
							`}
						>
							<MdClose size={24} />
						</Button>
					</Flex>
					<Flex
						width={1}
						alignItems="center"
						justifyContent="center"
						p={4}
						pt={0}
					>
						<Flex
							flexDirection="column"
							alignItems="center"
							fontSize={['sm', 'md']}
							textAlign="center"
						>
							<Text fontSize="xl" mb={4} fontWeight="bold">
								{popup.title}
							</Text>
							{photo && (
								<Flex
									alignItems="flex-end"
									justifyContent="space-between"
									backgroundColor="grey"
									height={200}
									minHeight={100}
									color="white"
									width={1}
									position="relative"
									css={(theme: Theme) => css`
										background: url(${photo ?? ''});
										background-size: cover;
										background-position: center;
										border-bottom: 1px solid ${theme.colors.darkerGrey};
									`}
								/>
							)}
							<Box width="100%">
								<RTText value={popup.description} />
							</Box>
							{popup.descriptionPages.map(dp => (
								<Box key={dp.id} width="100%">
									<Text as="h2">{dp.title}</Text>
									<RTText value={dp.content} />
								</Box>
							))}
						</Flex>
					</Flex>
				</Paper>
			</Dialog>
		</Wrapper>
	);
};

const PopupPreview: React.FC<{ id: string }> = ({ id }) => {
	const [show, setShow] = useState(false);
	return (
		<Button ml={2} onClick={() => setShow(true)}>
			<IoMdEye size={24} />
			{show && <Popup id={id} closeModal={() => setShow(false)} />}
		</Button>
	);
};

export default PopupPreview;
