/** @jsxImportSource @emotion/core */

import { useFormik, getIn } from 'formik';
import React, { FC } from 'react';

import Text from 'components/styled/Text';
import Form from 'components/form';
import TextInput from 'components/form/input/TextInput';
import { Flex, Box } from 'components/styled';

import { getStudentProfileFormInitialValues } from '../profile/edit/config';

import { introFormSchemas } from './validations';

import {
	FormCommonProps,
	IntroPaper,
	IntroShadowButton,
	SectionLabel,
} from '.';

const IntroForm1: FC<FormCommonProps> = ({ student, afterSubmit, formId }) => {
	// Form
	const formikContext = useFormik({
		initialValues: getStudentProfileFormInitialValues(student),
		validationSchema: introFormSchemas.form1,
		onSubmit: values => {
			console.log('submitted');
			afterSubmit(values);
		},
	});

	const {
		handleSubmit,
		handleBlur,
		handleChange,
		values,
		errors,
		touched,
		isSubmitting,

		submitCount,
	} = formikContext;
	const wasSubmitted = submitCount !== 0;

	return (
		<Form id={formId} onSubmit={handleSubmit}>
			<Flex flexWrap="wrap" width={1} alignItems="center">
				<Box width={1} mb={1}>
					<SectionLabel label="Jméno" />
					<IntroPaper minHeight={50} justifyContent="center">
						{student?.firstName ?? ''}
					</IntroPaper>
				</Box>
				<Box width={1} mb={1}>
					<SectionLabel label="Příjmení" />
					<IntroPaper minHeight={50} justifyContent="center">
						{student?.lastName ?? ''}
					</IntroPaper>
				</Box>
				<Box width={1}>
					<SectionLabel label="Telefon" />
					<IntroPaper>
						<TextInput
							id="phone"
							name="phone"
							label=""
							labelType={'inline'}
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.phone}
							error={getIn(errors, 'phone')}
							touched={wasSubmitted || getIn(touched, 'phone')}
							labelMinWidth="90px"
							disabled={isSubmitting}
						/>
					</IntroPaper>
				</Box>
				<Box width={1} mb={3}>
					<SectionLabel label="Registrační = kontaktní e-mail" />
					<IntroPaper>
						<TextInput
							id="contactEmail"
							name="contactEmail"
							label=""
							labelType={'inline'}
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.contactEmail}
							error={getIn(errors, 'contactEmail')}
							touched={wasSubmitted || getIn(touched, 'contactEmail')}
							labelMinWidth="90px"
							disabled={isSubmitting}
						/>
					</IntroPaper>
					<Text fontSize="sm" color="grey">
						TIP: Pokud svůj univerzitní e-mail moc nepoužíváš, změň svůj
						registrační/kontaktní e-mail na svůj soukromý (používanější), aby ti
						nic neuniklo.
					</Text>
				</Box>

				<Box width={1} mt={4} textAlign="center">
					<IntroShadowButton
						form={formId}
						variant="primary"
						type="submit"
						width={2 / 3}
					>
						Další
					</IntroShadowButton>
				</Box>
			</Flex>
		</Form>
	);
};

export default IntroForm1;
