import { Flex, FlexProps } from 'components/styled';

import styled from 'theme/styled';

const Form = styled(Flex)<FlexProps>``.withComponent('form');

Form.defaultProps = {
	flexDirection: 'column',
};

export default Form;
