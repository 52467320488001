import { InfiniteScroll } from 'libreact/lib/InfiniteScroll';
import React, { FC, ReactNode } from 'react';

import { Box, Flex, Grid } from 'components/styled';
import Text from 'components/styled/Text';
import LoaderBounce from 'components/loaders/LoaderBounce';
import Paper from 'components/styled/Paper';

import { Loader } from 'modules/loader';

import fileSearchingImage from 'assets/file-searching.gif';

type Props = {
	isEmpty: boolean;
	isLoading?: boolean;
	noResults?: string | ReactNode;
	fullWidthItems?: boolean;
	tileSize?: string;
	gridGap?: number;
};

const TileGrid: FC<Props> = ({
	isEmpty,
	isLoading,
	noResults = 'Žádné položky nevyhovují požadavkům',
	fullWidthItems,
	tileSize = '320px',
	gridGap = 4,
	children,
}) => (
	<>
		{isLoading && <Loader />}
		{!isLoading && isEmpty && (
			<Flex flexDirection="column" justifyContent="center" flexGrow={1} my={3}>
				{typeof noResults === 'string' ? (
					<Paper alignItems="center" justifyContent="center">
						<Text fontWeight="bold" textAlign="center" fontSize="xl">
							{noResults}
						</Text>
						<Box
							as={p => (
								<img src={fileSearchingImage} alt="Žádné výsledky" {...p} />
							)}
							mb={3}
						/>
					</Paper>
				) : (
					noResults
				)}
			</Flex>
		)}
		{!isLoading && !isEmpty && (
			<Grid
				gridGap={gridGap}
				gridTemplateColumns={[
					`1fr`,
					`repeat(auto-fill, ${
						fullWidthItems ? '1fr' : `minmax(${tileSize}, 1fr)`
					})`,
				]}
				justifyItems={fullWidthItems ? 'stretch' : 'center'}
				alignItems="stretch"
			>
				{children}
			</Grid>
		)}
	</>
);
export default TileGrid;

type InfiniteGridProps = {
	page: number;
	fetchMore: () => void;
	hasMore: boolean;
} & Props;

export const InfiniteGrid: FC<InfiniteGridProps> = ({
	isLoading,
	isEmpty,
	page,
	fetchMore,
	hasMore,
	noResults = 'Žádné položky nevyhovují požadavkům',
	fullWidthItems,
	tileSize,
	gridGap,
	children,
}) => (
	<TileGrid
		isEmpty={isEmpty}
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		isLoading={isLoading || page === -1}
		noResults={noResults}
		fullWidthItems={fullWidthItems}
		tileSize={tileSize}
		gridGap={gridGap}
	>
		<InfiniteScroll
			cursor={page}
			loadMore={fetchMore}
			hasMore={hasMore}
			sentinel={
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						width: '100%',
						marginTop: 32,
						marginBottom: 32,
					}}
				>
					<LoaderBounce />
				</div>
			}
		>
			{children}
		</InfiniteScroll>
	</TileGrid>
);
