import { useQuery } from 'react-query';

import { UASResult } from 'utils/UASTypes';

import { api } from '.';

import { Language } from 'typing/endpoints';

export const useLanguageList = () =>
	useQuery(
		'languages',
		() =>
			api()
				.get('languages', { searchParams: { pageSize: -1 } })
				.json<UASResult<Language>>(),
		{ staleTime: 3600000 },
	);
