import React, { useContext, ComponentProps, FC } from 'react';
import { background, color } from 'styled-system';

import styled from 'theme/styled';
import { FocusStyle } from 'theme/GlobalStyles';

import { FloatingMenuContext } from './utils';
import { MainButtonProps, StyledMainButtonProps } from './_typing';

const MainButtonStyled = styled.button<StyledMainButtonProps>`
	display: flex;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	cursor: pointer;
	padding: 0;
	-webkit-user-drag: none;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	width: ${p => p.size}px;
	height: ${p => p.size}px;

	&:focus {
		${p => FocusStyle(p.theme)}
	}

	${background}
	${color}
`;

const IconStyled = styled.div<{ isOpen: boolean }>`
	display: flex;
	position: absolute;
	transition: transform 300ms;
	transform: ${p => `rotate(${p.isOpen ? 180 : 0}deg)`};
`;

type Props = MainButtonProps & ComponentProps<typeof MainButtonStyled>;

const MainButton: FC<Props> = ({
	iconResting,
	iconActive,
	size = 56,
	...rest
}) => {
	const { isOpen } = useContext(FloatingMenuContext);
	return (
		<MainButtonStyled size={size} {...rest}>
			<IconStyled isOpen={isOpen}>
				{isOpen ? iconActive : iconResting}
			</IconStyled>
		</MainButtonStyled>
	);
};

export default MainButton;
