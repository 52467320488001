import { useReducer, useCallback } from 'react';
import { ReactourStep, ReactourStepContentArgs } from '@slavikdenis/reactour';

type UseTourOptions = {
	defaultStep?: number;
	onTourClose?: () => void;
	userCanCloseTour?: boolean;
};

export type TourSteps = ReactourStep[];

export type TourContentComponent = (
	args: ReactourStepContentArgs,
) => React.ReactNode;

type State = {
	currentStep: number;
	isOpen: boolean;
};

type Action =
	| { type: 'OPEN_TOUR' }
	| { type: 'CLOSE_TOUR' }
	| { type: 'SET_STEP'; step: number };

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case 'OPEN_TOUR':
			return {
				...state,
				isOpen: true,
			};
		case 'CLOSE_TOUR':
			return {
				...state,
				isOpen: false,
			};
		case 'SET_STEP':
			return {
				...state,
				currentStep: action.step,
			};
		default:
			throw new Error('Error: useTour -> reducer -> Unknown action type');
	}
}

export default function useTour({
	defaultStep = -1,
	onTourClose,
	userCanCloseTour = true,
}: UseTourOptions = {}) {
	// State
	const [{ currentStep, isOpen }, dispatch] = useReducer(reducer, {
		currentStep: defaultStep,
		isOpen: false,
	} as State);

	// Handlers
	const setStep = useCallback((step: number) => {
		dispatch({
			type: 'SET_STEP',
			step,
		});
	}, []);

	const showTour = useCallback(() => {
		dispatch({ type: 'OPEN_TOUR' });
	}, []);

	const closeTour = useCallback(() => {
		if (userCanCloseTour) {
			dispatch({ type: 'CLOSE_TOUR' });
		}
		onTourClose?.();
	}, [onTourClose, userCanCloseTour]);

	const forceCloseTour = useCallback(() => {
		dispatch({ type: 'CLOSE_TOUR' });
		onTourClose?.();
	}, [onTourClose]);

	return {
		currentStep,
		isOpen,
		setStep,
		showTour,
		closeTour,
		forceCloseTour,
	};
}
