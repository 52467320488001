/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';

import ErrorScreen from 'components/error/ErrorScreen';
import LoaderSpin from 'components/loaders/LoaderSpin';
import { Box, Flex } from 'components/styled';
import Button, { NavLinkButton } from 'components/styled/Button';
import Paper from 'components/styled/Paper';
import Text from 'components/styled/Text';
import TitleText from 'components/styled/TitleText';
import { ResponsiveWrapper, Wrapper } from 'components/styled/Wrapper';

import { Loader } from 'modules/loader';
import studentLoginBgImg from 'modules/student/assets/bg-min.jpg';

import { useUserToken } from 'auth';
import {
	IntroFormIcon1,
	IntroFormIcon2,
	IntroFormIcon3,
	IntroFormIcon4,
	IntroFormIcon5,
} from 'assets';
import { styled } from 'theme';

import { useStudent } from 'api/studentApi';

import useLogout from 'hooks/useLogout';
import useBreakpoint from 'hooks/useBreakpoint';

import { StudentProfileFormValues } from '../profile/edit/config';

import IntroForm1 from './IntroForm1';
import IntroForm2 from './IntroForm2';
import IntroForm3 from './IntroForm3';
import IntroForm4 from './IntroForm4.';
import IntroForm5 from './IntroForm5';
import ProgressBar from './ProgressBar';

import { Student } from 'typing/endpoints';

export const IntroPaper = styled(Paper)`
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
	padding: 16px 8px;
	margin: 0px 0px;
`;

export const IntroShadowButton = styled(Button)`
	box-shadow: 2px 3px 5px 2px rgb(0 0 0 / 10%);
`;

export const SectionLabel: FC<{ label: string; percents?: number }> = ({
	label,
	percents,
}) => (
	<Flex mt={4} mb={2} fontWeight="bold" justifyContent="space-between">
		<Text my={0}>{label}</Text>{' '}
		{percents && (
			<Text my={0} color="primary">
				+{percents} %
			</Text>
		)}
	</Flex>
);

const ICON_SIZE = 100;

const getFormHeader = (
	step: number,
	firstLogin?: boolean,
): {
	Icon: JSX.Element;
	title: string;
	subtitle: string[]; // | JSX.Element;
	FormComponent: React.FC<FormCommonProps>;
	formId: string;
} =>
	[
		{
			Icon: <IntroFormIcon1 size={ICON_SIZE} color="primary" />,
			title: 'Profil je základ.',
			subtitle: [
				'Tento průvodce ti pomůže vyplnit tvůj profil. Zabere to',
				'jen pár minut a vše následně můžeš upravit nebo doplnit.',
				'Jen díky tomu ti může k+ portál fungovat správně.',
			],
			FormComponent: IntroForm1,
			formId: 'intro-form-1',
		},
		{
			Icon: <IntroFormIcon2 size={ICON_SIZE} color="primary" />,
			title: 'Skvěle, teď k tobě...',
			subtitle: [
				'Kdo doopravdy jsi? Dobře napsaný osobní medailonek',
				'je 80 % úspěchu. Zároveň jde o nejčtenější část profilu.',
			],
			FormComponent: IntroForm2,
			formId: 'intro-form-2',
		},
		{
			Icon: <IntroFormIcon3 size={ICON_SIZE} color="primary" />,
			title: 'Co umíš a ovládáš?',
			subtitle: [
				'Dovednost je učením a praxí získaná vlastnost,',
				'která vyjadřuje tvoje schopnosti.',
			],
			FormComponent: IntroForm3,
			formId: 'intro-form-3',
		},
		{
			Icon: <IntroFormIcon4 size={ICON_SIZE} color="primary" />,
			title: 'Kam míříš?',
			subtitle: [
				'Co už máš za sebou a co tě nyní zajímá?',
				'Popiš své zkušenosti a preference, ať ti umíme doporučit vhodné nabídky spolupráce.',
			],
			FormComponent: IntroForm4,
			formId: 'intro-form-4',
		},
		{
			Icon: <IntroFormIcon5 size={ICON_SIZE} color="primary" />,
			title: firstLogin
				? 'Výborně! \nTvoje registrace je hotová.\n'
				: 'Výborně! \nAktualizace profilu je hotová.\n',
			subtitle: [
				'Se svým profilem však pracuj dál. Promítni',
				'do něj každou změnu ve svém profesním životě:',
				' novou brigádu, zkušenost, práci na projektu.',
				'Zvýšíš si tím atraktivitu svého profilu.',
			],
			FormComponent: IntroForm5,
			formId: 'intro-form-5',
		},
	][step];

export type FormCommonProps = {
	student: Student;
	afterSubmit: (
		studentValues: StudentProfileFormValues,
		profileCompletenessIncrement?: number,
	) => void;
	formId: string;
};

const getDays = (ms: number) => ms / 1000 / 60 / 60 / 24;

const NEW_REGISTRATION_MAX_DAYS = 7;
const SECONDS_UNTIL_REDIRECT = 4;
export const THRESHOLD_TO_SHOW_INTRO_FORM = 90;

const checkNewRegistration = (dateCreated: Date) => {
	const created = new Date(dateCreated ?? new Date()).getTime();
	const now = new Date().getTime();
	return getDays(now - created) < NEW_REGISTRATION_MAX_DAYS;
};

const StudentIntroductionForm = () => {
	const stepsCount = 5;
	const [step, setStep] = useState(0);
	const [profileCompleteness, setProfileCompleteness] = useState(0);

	const [welcomeScreen, setWelcomeScreen] = useState<
		'show' | 'loading' | 'done'
	>('loading');
	const [studenUpdateDto, setStudentUpdateDto] = useState<
		Student | StudentProfileFormValues | undefined
	>();
	const user = useUserToken();

	const handleLogOut = useLogout(user);
	const isMobile = useBreakpoint(0);

	const studentId = user?.id ?? '';

	// Data
	const response = useStudent(studentId);

	const student = response.data;

	useEffect(() => {
		setStudentUpdateDto(student);
		setProfileCompleteness(student?.profileCompleteness ?? 0);
		if (student && welcomeScreen === 'loading') {
			setWelcomeScreen('show');
		}
	}, [student, welcomeScreen]);

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (welcomeScreen === 'show') {
			timeout = setTimeout(() => {
				setWelcomeScreen('done');
			}, SECONDS_UNTIL_REDIRECT * 1000);
		}
		return () => clearTimeout(timeout);
	}, [welcomeScreen]);

	useEffect(() => {
		if (
			parseInt((user?.profileCompleteness ?? '0') as string) >=
			THRESHOLD_TO_SHOW_INTRO_FORM
		) {
			window.location.replace('/');
		}
	}, [user]);

	if (response.isLoading) {
		return (
			<Wrapper
				css={css`
					background-image: url(${studentLoginBgImg});
					background-size: cover;
					background-attachment: fixed;
				`}
			>
				<Loader />
			</Wrapper>
		);
	}
	if (!studenUpdateDto) {
		return <ErrorScreen {...response} />;
	}

	const { Icon, title, subtitle, FormComponent, formId } = getFormHeader(
		step,
		(user?.profileCompleteness ?? 0) < 30,
	);
	//TODO: pouzit asi miesto merania dni od created profileCompleteness < 30 aj tu
	const isNewRegistration = student?.created
		? checkNewRegistration(student.created)
		: false;

	return (
		<Wrapper
			css={css`
				background-image: url(${studentLoginBgImg});
				background-size: cover;
				background-position: center;
				background-attachment: fixed;
			`}
		>
			<ResponsiveWrapper minHeight="100vh" alignItems="center">
				<Flex
					maxWidth="600px"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
					width={
						welcomeScreen === 'show' ? '390px' : [1, 1, 5 / 7, 4 / 7, 6 / 10]
					}
					p={3}
					px={45}
					mt={welcomeScreen === 'show' ? 'auto' : 4}
					mb={welcomeScreen === 'show' ? 'auto' : 'unset'}
					css={css`
						background-color: rgba(240, 240, 240, 0.95);
						box-shadow: 0px 2px 12px 2px rgb(0 0 0 / 28%);
					`}
				>
					{welcomeScreen === 'show' ? (
						<Flex minHeight="300px" mt={4} flexDirection="column">
							<TitleText
								my={0}
								p={1}
								fontSize="30px"
								css={css`
									white-space: pre;
								`}
							>
								{isNewRegistration ? 'Vítej v k+ portálu!' : 'Buď nejlepší!'}
							</TitleText>
							<Text my={3} textAlign="center">
								{isNewRegistration ? (
									<>
										Nejdříve ti pomůžeme <br /> s tím nejdůležitějším...
									</>
								) : (
									<>
										Vyplň si profil naplno <br /> a zařaď se mezi TOP 6 % <br />{' '}
										registrovaných studentů.
									</>
								)}
							</Text>
							<Flex
								mt={5}
								width={1}
								alignItems="center"
								justifyContent="center"
							>
								<LoaderSpin />
							</Flex>
						</Flex>
					) : (
						<>
							{step < stepsCount - 1 && (
								<Flex
									width={1}
									justifyContent="space-between"
									alignItems="flex-start"
									m={0}
									p={0}
								>
									<NavLinkButton
										m={0}
										p={0}
										mx="-8px"
										to="#"
										onClick={handleLogOut}
										variant="text"
									>
										Odhlásit se
									</NavLinkButton>
									{/* <NavLinkButton
										m={0}
										p={0}
										mx="-8px"
										to="#"
										onClick={() => replace('/dashboard')}
										variant="text"
									>
										Vyplnit později
									</NavLinkButton> */}
								</Flex>
							)}
							<Flex
								mt={4}
								width={1}
								alignItems="center"
								justifyContent="center"
							>
								{Icon}
							</Flex>
							<Box maxWidth="400px">
								<TitleText
									my={0}
									p={1}
									css={css`
										white-space: pre;
									`}
								>
									{title}
								</TitleText>

								{isMobile ? (
									<Text my={0} textAlign="center">
										{subtitle.join(' ')}
									</Text>
								) : (
									<>
										{subtitle.map(s => (
											<Text key={s} my={0} textAlign="center">
												{s}
											</Text>
										))}
									</>
								)}
								<Box mb={3} />
							</Box>

							<ProgressBar
								currentStep={step}
								stepsTotal={stepsCount}
								profileCompleteness={profileCompleteness}
							/>
							{studenUpdateDto && (
								<FormComponent
									formId={formId}
									student={studenUpdateDto as Student}
									afterSubmit={(
										studentValues,
										profileCompletenessIncrement = 0,
									) => {
										setStudentUpdateDto(studentValues);
										setProfileCompleteness(
											p => p + profileCompletenessIncrement,
										);
										setStep(p => p + 1);
									}}
								/>
							)}
							{step > 0 && step < stepsCount - 1 && (
								<Flex justifyContent="center" width={1} alignItems="center">
									<Button
										onClick={() => setStep(p => p - 1)}
										variant="text"
										ml="-13px"
										mt={2}
										py={2}
										css={css`
											&:hover {
												background-color: rgba(0, 0, 0, 0.03);
											}
										`}
									>
										<MdArrowBack /> Zpět
									</Button>
								</Flex>
							)}

							{step < stepsCount - 1 && (
								<Text>
									<b>
										{step + 1} / {stepsCount - 1}
									</b>
								</Text>
							)}
						</>
					)}
				</Flex>
			</ResponsiveWrapper>
		</Wrapper>
	);
};

export default StudentIntroductionForm;
