import React, { FC, useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import imageCompression from 'browser-image-compression';
import { MdEdit } from 'react-icons/md';

import ModalDialog from 'components/modal';
import Paper from 'components/styled/Paper';
import Button from 'components/styled/Button';
import SliderInput from 'components/form/slider/SliderInput';
import { Box, Flex } from 'components/styled';
import { CroppableProps } from 'components/form/file/_typing';
import { getCroppedImg, readFile } from 'components/form/file/_utils';
import Text from 'components/styled/Text';

export type EditPhotoProps = {
	photoUrl?: string;
	onFileCropped: (newFile: File) => void;
	editLabel: string;
	croppable: CroppableProps;
};

const EditPhoto: FC<EditPhotoProps> = ({
	photoUrl,
	onFileCropped,
	editLabel,
	croppable,
}) => {
	const [zoom, setZoom] = useState(1);
	const [fileToCrop, setFileToCrop] = useState<string | null>(null);
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

	const handleSetCrop = useCallback((_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const setFilesWithCompression = useCallback(
		async (file: File) => {
			let compressedFile: File;

			try {
				const compressed = await imageCompression(file, {
					maxSizeMB: 1,
					maxWidthOrHeight: 1920,
				});
				if (compressed instanceof Blob) {
					compressedFile = new File([compressed], file.name);
				} else {
					compressedFile = compressed;
				}
			} catch (err) {
				compressedFile = file;
			}

			//setNewFile(compressedFile);
			onFileCropped(compressedFile);
		},
		[onFileCropped],
	);

	const handleFileCropped = useCallback(async () => {
		if (fileToCrop && croppedAreaPixels) {
			try {
				const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
				// Reset cropped state
				setFileToCrop(null);
				setCrop({ x: 0, y: 0 });
				setZoom(1);
				// Set cropped file
				await setFilesWithCompression(croppedImage);
			} catch (e) {
				console.error(e);
			}
		}
	}, [croppedAreaPixels, fileToCrop, setFilesWithCompression]);

	useEffect(() => {
		const getImageData = async () => {
			if (photoUrl) {
				const x = await (await fetch(photoUrl)).blob();
				const file = new File([x], 'logo');
				const imageDataUrl = await readFile(file);
				setFileToCrop(imageDataUrl);
			}
		};
		getImageData();
	}, [photoUrl]);

	return (
		<ModalDialog
			label={editLabel}
			control={openModal => (
				<Flex px={2} color="text" opacity={1} zIndex={1}>
					<Button onClick={openModal} variant="primary">
						<MdEdit size={16} />
						<Text as="span" ml={2}>
							{editLabel}
						</Text>
					</Button>
				</Flex>
			)}
		>
			{closeModal => (
				<Paper>
					<Text>{editLabel}</Text>

					{fileToCrop && (
						<Box width={1}>
							<SliderInput
								id="crop-zoom"
								value={zoom}
								setFieldValue={(_, val) => setZoom(val ?? 1)}
								min={1}
								max={3}
								step={0.0001}
								valuePreview={val => (
									<Box position="relative" width={1} height={240}>
										<Cropper
											image={fileToCrop}
											crop={crop}
											zoom={val}
											aspect={croppable.aspect}
											cropShape={croppable.cropShape ?? 'rect'}
											onCropChange={setCrop}
											onCropComplete={handleSetCrop}
											onZoomChange={setZoom}
										/>
									</Box>
								)}
							/>
						</Box>
					)}
					<Flex width={1} justifyContent="space-between">
						<Button variant="text" onClick={closeModal}>
							{'<'} Zpět
						</Button>
						<Button
							my={3}
							variant="primary"
							onClick={async () => {
								await handleFileCropped();
								closeModal();
							}}
							disabled={croppedAreaPixels === null}
						>
							Oříznout
						</Button>
					</Flex>
				</Paper>
			)}
		</ModalDialog>
	);
};

export default EditPhoto;
