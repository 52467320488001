/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Dialog from '@reach/dialog';

import { Box, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import Button from 'components/styled/Button';
import Paper from 'components/styled/Paper';
import TitleText from 'components/styled/TitleText';
import Checkbox from 'components/form/checkbox/Checkbox';
import Divider from 'components/styled/Divider';

import CookiesImg from 'assets/cookies.png';

import Store from 'utils/Store';

import ModalDialog from '.';

export type TStored = {
	gAnalytics?: boolean;
	fbPixel?: boolean;
};

export const useCookiesSettings = () =>
	useMemo(() => Store.get<TStored>('cookies-settings', undefined), []);

type CookiesSettingsProps = {
	fbPixel: boolean;
	gAnalytics: boolean;
	handleFbPixel: React.Dispatch<React.SetStateAction<boolean>>;
	handleGAnalytics: React.Dispatch<React.SetStateAction<boolean>>;
};
const CookiesSettingsDialog: FC<CookiesSettingsProps> = ({
	fbPixel,
	gAnalytics,
	handleFbPixel,
	handleGAnalytics,
}) => {
	return (
		<ModalDialog
			label="pomocnik cookies"
			control={openModal => (
				<Button
					fontSize={['16px', '17px']}
					width={['100%', '50%']}
					onClick={() => {
						openModal();
					}}
				>
					Nastavení výběru
				</Button>
			)}
		>
			{closeModal => (
				<Paper
					css={css`
						z-index: 999999;
					`}
				>
					<TitleText my={0}>Upravit nastavení cookies</TitleText>
					<Text fontWeight="bold">O cookies</Text>
					<Text>
						Cookies jsou malé soubory, do kterých webové stránky mohou ukládat
						informace o vaší aktivitě a preferencích. Používají se například k
						tomu, aby si prohlížeč zapamatoval Vaše přihlášení nebo aby se
						uložila Vaše volba v tomto okně.
					</Text>

					<Text>
						Pro sledování návštěvnosti používáme nástroje poskytované třetími
						stranami. Veškerá nasbíraná data jsou anonymizována, vaše osobní
						údaje sbírány nejsou.
					</Text>
					<Box mt={2} mb={3} width="100%">
						<Checkbox
							id="ga"
							label="Google Analytics"
							onChange={() => handleGAnalytics(p => !p)}
							checked={gAnalytics}
						/>
						<Text fontSize="sm">
							Tento nástroj používáme k vyhodnocování návštěvnosti našeho webu.
							Nastavuje cookies, podle kterých od sebe rozeznává uživatele, a to
							bez jejich přímé identifikace.
						</Text>
					</Box>
					<Box mt={2} width="100%">
						<Checkbox
							id="fbPixel"
							label="Facebook Pixel"
							onChange={() => handleFbPixel(p => !p)}
							checked={fbPixel}
						/>
						<Text fontSize="sm">
							Tento nástroj používáme k vyhodnocování návštěvnosti našeho webu.
							Nastavuje cookies, podle kterých od sebe rozeznává uživatele, a to
							bez jejich přímé identifikace.
						</Text>
					</Box>
					<Divider my={3} />

					<Flex justifyContent="flex-end">
						<Button
							variant="primary"
							onClick={() => {
								closeModal();
							}}
							mx={3}
						>
							Uložit
						</Button>
					</Flex>
				</Paper>
			)}
		</ModalDialog>
	);
};

const CookiesDialog = () => {
	const [stored, setStored] = useState<TStored | undefined>(undefined);
	const [gAnalytics, setGanalytics] = useState(stored?.gAnalytics ?? true);
	const [fbPixel, setFbPixel] = useState(stored?.fbPixel ?? true);

	const handleDecline = () => {
		const settings = {
			gAnalytics: false,
			fbPixel: false,
		};
		Store.set('cookies-settings', settings);
		setStored(settings);
	};

	const handleConfirm = () => {
		const settings = {
			gAnalytics,
			fbPixel,
		};
		Store.set('cookies-settings', settings);
		setStored(settings);
		window.location.reload();
	};

	useEffect(() => {
		setStored(Store.get<TStored>('cookies-settings', undefined));
	}, []);
	//nastavenia asi presunut do paticky, nie takto
	if (stored || window.location.pathname.includes('embed/offers')) {
		return null;
	}

	return (
		<Dialog isOpen={true}>
			<Flex alignItems="center" justifyContent="center">
				<Paper
					maxWidth={[350, 480]}
					width={[350, 480]}
					minWidth={[350, 480]}
					m={0}
					px={[3, 3]}
					py={[1, 3]}
				>
					<Flex
						justifyContent={['space-between']}
						flexDirection="column"
						alignItems="center"
						mt={2}
					>
						<Text
							textAlign="center"
							fontWeight="bold"
							fontSize={['xl', '34px']}
						>
							I kariéra
							<br /> našich sušenek...
						</Text>
						<Flex my={4}>
							<img src={CookiesImg} height="130px" />
						</Flex>
						<Flex flexDirection="column" justifyContent="flex-start" ml={3}>
							<Text
								textAlign="center"
								fontWeight={['normal', 'bold']}
								my={0}
								fontSize={['16px', '19px']}
							>
								...je ve vašich rukou. Dejte jim <br /> příležitost uplatnit se
								ve svém oboru!
							</Text>
						</Flex>

						<Flex
							flexDirection={['column', 'row']}
							width="100%"
							mt={3}
							alignItems={['center', 'stretch']}
							justifyContent={['stretch', 'flex-start']}
						>
							<CookiesSettingsDialog
								fbPixel={fbPixel}
								gAnalytics={gAnalytics}
								handleFbPixel={setFbPixel}
								handleGAnalytics={setGanalytics}
							/>
							<Button
								fontSize={['16px', '17px']}
								ml={[0, 3]}
								mt={[3, 0]}
								variant="primary"
								onClick={handleConfirm}
								width={['100%', '50%']}
							>
								Povolit cookies
							</Button>
						</Flex>
						<Flex justifyContent="space-between" mt={2}>
							<Button
								fontSize="13px"
								variant="text"
								px={0}
								py={1}
								onClick={handleDecline}
								css={css`
									&:hover {
										text-decoration: underline;
									}
								`}
							>
								Odmítnout doporučený výběr cookies a zamezit tak vylepšování
								webu
							</Button>
						</Flex>
						<Text fontSize="12px" color="textLight">
							Tento web ukládá tzv. nezbytné cookies, k ukládání dalších typů
							cookies je potřeba souhlas uživatele. Další informace o cookies
							najdete v nastavení.
						</Text>
					</Flex>
				</Paper>
			</Flex>
		</Dialog>
	);
};

export default CookiesDialog;
