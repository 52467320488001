import React from 'react';

import { Box, Flex } from 'components/styled';
import Text, { Emoji } from 'components/styled/Text';
import { NavButton } from 'components/styled/Button';

import { TourContentComponent } from 'modules/tutorials/useTour';

export const Welcome: TourContentComponent = ({ close, goTo }) => (
	<Box textAlign="center">
		<Text>Gratulujeme k úspěšné registraci a buďte tu jako doma!</Text>
		<Emoji symbol="😍" label="Smile" />
		<Text>Teď Vám ukážeme, co a jak upravit, vložit, odkrýt, zveřejnit.</Text>
		<Text>Zabere to jen chvilku… Fakt.</Text>

		<Flex justifyContent="space-between">
			<NavButton mt={2} onClick={close}>
				Teď ne
			</NavButton>
			<NavButton variant="primary" mt={2} onClick={() => goTo(1)}>
				Pojďme na to!
			</NavButton>
		</Flex>
	</Box>
);
