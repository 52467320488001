import { ReactourStep } from '@slavikdenis/reactour';

import { TutorialCompanyProfileIDs as IDs } from './_ids';
import { Welcome } from './steps/FirstSteps';
import { HeaderStep } from './steps/DetailSteps';
import {
	BasicInfoStep,
	ContactInfoStep,
	LeadParagraphStep,
	HowItLookStep,
	AdmissionProcessStep,
	OwnSectionsStep,
	GalleryStep,
	FinalStep,
	PreviewProfileButtonStep,
} from './steps/EditSteps';

// Tour steps
const COMPANY_PROFILE_TUTORIAL_STEPS: ReactourStep[] = [
	{ content: Welcome },
	{
		selector: `#${IDs.INTRO.PAGE_HEADER}`,
		content: HeaderStep,
	},
	// {
	// 	selector: `#${IDs.DETAIL.FLOATING_MENU_BUTTON_ID}`,
	// 	content: FloatingButtonStep,
	// },
	// {
	// 	selector: `#${IDs.DETAIL.EDIT_BUTTON_ID}`,
	// 	content: EditButtonStep,
	// },
	// {
	// 	selector: `#${IDs.EDIT.COMPANY_HEADER_ID}`,
	// 	content: EditHeaderStep,
	// },
	{
		selector: `#${IDs.EDIT.COMPANY_BASIC_INFO_ID}`,
		content: BasicInfoStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_CONTACT_ID}`,
		content: ContactInfoStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_LEAD_PARAGRAPH_ID}`,
		content: LeadParagraphStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_DESCRIPTION_PAGES.HOW_IT_LOOK_ID}`,
		content: HowItLookStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_DESCRIPTION_PAGES.ADMISSION_PROCESS_ID}`,
		content: AdmissionProcessStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_OWN_SECTIONS_ID}`,
		content: OwnSectionsStep,
	},
	{
		selector: `#${IDs.EDIT.COMPANY_GALLERY_ID}`,
		content: GalleryStep,
	},
	{
		selector: `#${IDs.EDIT.PREVIEW_PROFILE}`,
		content: PreviewProfileButtonStep,
	},
	// {
	// 	selector: `#${IDs.EDIT.FLOATING_MENU_BUTTON_ID}`,
	// 	content: MenuSaveStep,
	// },
	// {
	// 	selector: `#${IDs.EDIT.SAVE_BUTTON_ID}`,
	// 	content: SaveStep,
	// },
	{ content: FinalStep },
];

export default COMPANY_PROFILE_TUTORIAL_STEPS;
