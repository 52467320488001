import React from 'react';

import { Box } from 'components/styled';
import Text, { Emoji } from 'components/styled/Text';

import { TourContentComponent } from 'modules/tutorials/useTour';

export const HeaderStep: TourContentComponent = () => (
	<Box textAlign="center">
		<Text>
			Na Váš profil se vždy dostanete přes menu v horní části stránky kliknutím
			na Vaše logo.
		</Text>
	</Box>
);

export const FloatingButtonStep: TourContentComponent = () => (
	<Box textAlign="center">
		<Text>Chcete-li vyplnit profil, klikněte tady.</Text>

		<Text fontStyle="italic">
			Tip: Mimochodem, pokud budete chtít kdykoli, kdekoli, cokoli editovat,
			<br />
			využijte právě toto tlačítko. Bude vždy s Vámi{' '}
			<Emoji symbol="😉" label="Wink" />
		</Text>
	</Box>
);

export const EditButtonStep: TourContentComponent = () => (
	<Text>A pak hned tady.</Text>
);
