/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import imageCompression from 'browser-image-compression';
import { getOrientation } from 'get-orientation/browser';
import React, {
	Fragment,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { MdDelete } from 'react-icons/md';

import ErrorFeedback from 'components/form/error/ErrorFeedback';
import ModalDialog from 'components/modal';
import { Box, Flex } from 'components/styled';
import Button from 'components/styled/Button';
import Paper from 'components/styled/Paper';
import Text from 'components/styled/Text';

import EditPhoto, {
	EditPhotoProps,
} from 'modules/company/profile/edit/EditPhoto';

import { CheckmarkIcon, CrossIcon, UploadIcon } from 'assets';
import { Theme } from 'theme';

import SliderInput from '../slider/SliderInput';

import ContentNode from './ContentNode';
import { ReactComponent as ImageBackground } from './image-background.svg';
import { FileInputProps } from './_typing';
import {
	getAcceptedFileTypes,
	getCroppedImg,
	getRotatedImage,
	ORIENTATION_TO_ANGLE,
	readFile,
} from './_utils';

export enum FileAccept {
	Audio = 'audio/*',
	Video = 'video/*',
	Image = 'image/*',
}

const FileInput: React.FC<
	FileInputProps & {
		withEditButton?: boolean;
		onOriginalPhotoRemove?: () => void;
		TipNode?: ReactNode | string;
	} & Partial<EditPhotoProps>
> = ({
	id,
	label,
	disabled,
	accept,
	error,
	touched,
	onSetTouched,
	croppable,
	children,
	value,
	photoUrl,
	editLabel,
	withEditButton,
	onFileCropped,
	onOriginalPhotoRemove,
	TipNode,
	...p
}) => {
	const { onSetValue, multiple } = p;

	// Cropping
	const isCroppable = croppable !== undefined;
	const [fileToCrop, setFileToCrop] = useState<string | null>(null);
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

	// Internal state
	const [files, setFiles] = useState(
		// eslint-disable-next-line no-nested-ternary
		value ? (Array.isArray(value) ? value : [value]) : [],
	);

	const filePreview = useMemo(
		() => (files?.length > 0 ? URL.createObjectURL(files[0]) : ''),
		[files],
	);

	//console.log({ files });

	const setFilesWithCompression = useCallback(
		async (files: File[]) => {
			if (accept !== FileAccept.Image) {
				return setFiles(files);
			}

			const compressedFiles: File[] = [];
			for (let i = 0; i < files.length; ++i) {
				const file = files[i];
				try {
					const compressed = await imageCompression(file, {
						maxSizeMB: 5,
						maxWidthOrHeight: 3840,
					});
					if (compressed instanceof Blob) {
						compressedFiles.push(new File([compressed], file.name));
					} else {
						compressedFiles.push(compressed);
					}
				} catch (err) {
					compressedFiles.push(file);
				}
			}
			setFiles(compressedFiles);
		},
		[accept],
	);

	// Reset internal state if id changes
	useEffect(() => {
		setFiles([]);
		setFileToCrop(null);
		setCrop({ x: 0, y: 0 });
		setZoom(1);
	}, [id]);

	const handleFileSet = useCallback(
		async (file: File[]) => {
			if (isCroppable) {
				let imageDataUrl = await readFile(file[0]);
				// apply rotation if needed
				const orientation = await getOrientation(file[0]);
				const rotation = ORIENTATION_TO_ANGLE[orientation];
				if (rotation) {
					imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
				}
				// Set image to crop
				setFileToCrop(imageDataUrl);
			} else {
				await setFilesWithCompression(file);
			}
		},
		[isCroppable, setFilesWithCompression],
	);

	const handleFileCropped = useCallback(async () => {
		if (fileToCrop && croppedAreaPixels) {
			try {
				const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
				// Reset cropped state
				setFileToCrop(null);
				setCrop({ x: 0, y: 0 });
				setZoom(1);
				// Set cropped file
				await setFilesWithCompression([croppedImage]);
			} catch (e) {
				console.error(e);
			}
		}
	}, [croppedAreaPixels, fileToCrop, setFilesWithCompression]);

	const handleSetCrop = useCallback((_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const resetValue = useCallback(() => {
		setFiles([]);
		setFileToCrop(null);
		onSetTouched(id, true);
		onSetValue(id, null);
	}, [id, onSetTouched, onSetValue]);

	const setValue = useCallback(
		async (files: File[] | null) => {
			await setFilesWithCompression(files ?? []);
			setFileToCrop(null);
			onSetTouched(id, true);
			p.multiple
				? p.onSetValue(id, files ?? null)
				: p.onSetValue(id, files?.[0] ?? null);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id, onSetTouched, files, p.multiple, p.onSetValue],
	);

	const onFileDialogCancel = useCallback(() => {
		onSetTouched(id, true);
	}, [id, onSetTouched]);

	// Dropzone
	const {
		open,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragReject,
		fileRejections,
	} = useDropzone({
		onDrop: handleFileSet,
		disabled,
		onFileDialogCancel,
		multiple: Boolean(multiple),
		accept,
		noClick: true,
	});

	return (
		<ModalDialog label={label} control={children}>
			{closeModal => (
				<Paper px={0} py={0} position="relative" overflow="hidden">
					{accept === FileAccept.Image && (
						<ImageBackground
							css={css`
								position: absolute;
								min-width: 100%;
								height: auto;
							`}
						/>
					)}
					<div
						{...getRootProps()}
						css={(theme: Theme) => css`
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							padding: 64px ${theme.space[3]}px;
							border: 2px dashed
								${error && touched ? theme.colors.error : theme.colors.primary};
							flex: 1;
							z-index: 1;
						`}
					>
						<input {...getInputProps({ id })} />
						{isDragActive ? (
							<React.Fragment>
								{isDragReject ? (
									<ContentNode
										icon={<CrossIcon color="error" />}
										title="Soubor nemožno nahrát"
										subtitle={`Povolené typy souborů jsou ${accept}.`}
										// Placeholder to keep height of modal
										button={
											<Button opacity={0} my={3}>
												.
											</Button>
										}
									/>
								) : (
									<ContentNode
										icon={<CheckmarkIcon color="primary" />}
										title="Nahrát soubory"
										subtitle="Pusťte tažené soubory pro nahrání."
										// Placeholder to keep height of modal
										button={
											<Button opacity={0} my={3}>
												.
											</Button>
										}
									/>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								{croppable && fileToCrop !== null ? (
									<React.Fragment>
										<Box width={1}>
											<SliderInput
												id="crop-zoom"
												value={zoom}
												setFieldValue={(_, val) => setZoom(val ?? 1)}
												min={1}
												max={3}
												step={0.0001}
												valuePreview={val => (
													<Box position="relative" width={1} height={240}>
														<Cropper
															image={fileToCrop}
															crop={crop}
															zoom={val}
															aspect={croppable.aspect}
															cropShape={croppable.cropShape ?? 'rect'}
															onCropChange={setCrop}
															onCropComplete={handleSetCrop}
															onZoomChange={setZoom}
														/>
													</Box>
												)}
											/>
										</Box>
										<Button
											my={3}
											variant="primary"
											onClick={handleFileCropped}
											disabled={croppedAreaPixels === null}
										>
											Oříznout
										</Button>
										{withEditButton && editLabel && photoUrl && onFileCropped && (
											<Flex justifyContent="flex-end">
												<EditPhoto
													editLabel={editLabel}
													photoUrl={photoUrl}
													onFileCropped={newFile => {
														onFileCropped(newFile);
														setFiles([newFile]);
													}}
													croppable={croppable ?? { aspect: 1 }}
												/>
											</Flex>
										)}
									</React.Fragment>
								) : (
									<React.Fragment>
										{files.length > 0 ? (
											<ContentNode
												header={
													accept === FileAccept.Image &&
													filePreview && (
														<Box
															flexGrow={1}
															width="100%"
															height={250}
															css={css`
																background: url(${filePreview});
																background-position: center;
																background-size: contain;
																background-repeat: no-repeat;
															`}
														/>
													)
												}
												icon={
													accept !== FileAccept.Image && (
														<CheckmarkIcon color="primary" />
													)
												}
												title={
													files.length > 1
														? 'Soubory byly úspěšně nahrány'
														: 'Soubor byl úspěšně nahrán'
												}
												subtitle={files.map(f => f.name).join(', ')}
												button={
													<Button
														my={3}
														variant="primary"
														onClick={() => {
															setValue(files);
															closeModal();
														}}
													>
														Uložit
													</Button>
												}
											/>
										) : (
											<Fragment>
												{TipNode}
												<ContentNode
													icon={<UploadIcon color="primary" />}
													title="Přetáhněte zde svoje soubory"
													subtitle="nebo je vyberte z vaších souborů"
													button={
														<Button variant="primary" my={3} onClick={open}>
															Vybrat soubor
															{accept
																? ` (${getAcceptedFileTypes(accept)})`
																: ''}
														</Button>
													}
												/>

												{fileRejections.length > 0 && (
													<Fragment>
														<Text mb={0} color="error">
															Některé soubory se nepodařilo nahrát:
														</Text>
														{fileRejections.map(f => (
															<Text
																my={0}
																key={f.file.name}
																fontSize="sm"
																color="error"
															>
																{f.file.name} (
																{f.errors
																	.map(e =>
																		e.code === 'file-invalid-type'
																			? `Povolené typy souborů jsou ${accept}`
																			: e.message,
																	)
																	.join(', ')}
																)
															</Text>
														))}
													</Fragment>
												)}
											</Fragment>
										)}
										{withEditButton && editLabel && photoUrl && onFileCropped && (
											<Flex justifyContent="flex-end">
												<EditPhoto
													editLabel={editLabel}
													photoUrl={photoUrl}
													onFileCropped={newFile => {
														onFileCropped(newFile);
														setFiles([newFile]);
													}}
													croppable={croppable ?? { aspect: 1 }}
												/>
											</Flex>
										)}
										{onOriginalPhotoRemove && (
											<Button
												my={3}
												variant="primary"
												bg="error"
												onClick={() => {
													resetValue();
													onOriginalPhotoRemove?.();
												}}
											>
												<MdDelete />{' '}
												<Text my={0} py={0} ml={1}>
													Smazat původní fotku
												</Text>
											</Button>
										)}
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</div>
					<Flex
						position="absolute"
						bottom={0}
						zIndex={1}
						width={1}
						justifyContent="space-between"
					>
						<Button
							variant="text"
							onClick={() => {
								closeModal();
							}}
							css={css`
								&:hover {
									text-decoration: underline;
								}
							`}
						>
							{'<'} Zpět
						</Button>
						{(files.length > 0 || fileToCrop !== null) && (
							<Button
								variant="text"
								onClick={() => {
									resetValue();
								}}
								css={css`
									&:hover {
										text-decoration: underline;
									}
								`}
							>
								Zrušit změny / Nahrát nový soubor
							</Button>
						)}
					</Flex>

					{error && touched && (
						<Box position="absolute" bottom={0} right={0} mx={3} my={2}>
							<ErrorFeedback>{error}</ErrorFeedback>
						</Box>
					)}
				</Paper>
			)}
		</ModalDialog>
	);
};
export default FileInput;
