/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useQueryCache } from 'react-query';

import Button, { resetButtonStyle } from 'components/styled/Button';

import { useUserToken } from 'auth';

import { revealStudent } from 'api/studentApi';
import { useLoggedInCompany } from 'api/companyApi';

import { isRecruiterUser } from 'auth/token';

type Props = {
	identifier: string;
};

const RevealStudentButton: FC<Props> = ({ identifier, children }) => {
	// Token
	const token = useUserToken();
	const companyId = isRecruiterUser(token) ? token.companyId : '';

	// Company status
	const companyResponse = useLoggedInCompany();
	const reveals = companyResponse.data?.revealsCount ?? 0;
	const hasUnlimitedReveals =
		companyResponse.data?.hasUnlimitedReveals ?? false;
	const isCompanyActive = companyResponse.data?.isActive;
	const noReveals = reveals <= 0 && !hasUnlimitedReveals;

	// Cache
	const cache = useQueryCache();

	// Handlers
	const handleRevealStudent = useCallback(async () => {
		if (companyResponse.status === 'error') {
			companyResponse.refetch();
			return;
		}
		if (
			!window.confirm(
				`Aktuálně vám zůstává ${reveals} odkrytí. Jste si jistý, že chcete odkrýt daného studenta?`,
			)
		) {
			return;
		}

		try {
			if (!(await revealStudent(identifier, companyId))) {
				toast.error('Nepodařilo se odkrýt studenta');
				return;
			}
		} catch (error) {
			toast.error('Nepodařilo se odkrýt studenta');
		}

		cache.invalidateQueries('logged-in-company');
		cache.invalidateQueries(['company', companyId]);
		cache.invalidateQueries(['student', identifier]);
		cache.invalidateQueries('students/search');
	}, [companyResponse, reveals, cache, companyId, identifier]);

	return (
		<Button
			p={0}
			title={
				// eslint-disable-next-line no-nested-ternary
				companyResponse.status === 'loading'
					? 'Naćítám dostupná odkrytí...'
					: // eslint-disable-next-line no-nested-ternary
					companyResponse.status === 'error'
					? 'Nepodařilo se načíst odkrytí. Zkusit znovu?'
					: // eslint-disable-next-line no-nested-ternary
					!isCompanyActive
					? 'Pro odkrytí studenta musí být nejprve váš účet scvhálen správcem.'
					: noReveals
					? 'Nemáte dostatek odkrytí.'
					: undefined
			}
			disabled={
				companyResponse.status !== 'error' && (noReveals || !isCompanyActive)
			}
			onClick={handleRevealStudent}
			css={css`
				width: 100%;
				height: 100%;
				${resetButtonStyle}
			`}
		>
			{children}
		</Button>
	);
};

export default RevealStudentButton;
