/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC } from 'react';
import Dialog from '@reach/dialog';
import { MdClose } from 'react-icons/md';

import { Flex } from 'components/styled';
import TileGrid from 'components/tilegrid';
import Text from 'components/styled/Text';
import Button from 'components/styled/Button';
import CompanyEventTile from 'components/tiles/CompanyEventItem';

import { Loader } from 'modules/loader';

import { Theme } from 'theme';

import { useCalendarCompanyEventsOnSelectedDay } from './useCalendarCompanyEvents';

export const CompanyEventModal: FC<{
	day: Date;
	onClose: () => void;
}> = ({ day, onClose }) => {
	const response = useCalendarCompanyEventsOnSelectedDay(day);

	if (response.isLoading) {
		return <Loader />;
	}

	const events = response.data ?? [];
	const count = response.count;

	if (response.count === 0) {
		onClose();
		return null;
	}

	if (!count) {
		return null;
	}

	return (
		<Dialog
			isOpen={count > 0}
			onDismiss={onClose}
			css={(theme: Theme) => css`
				padding: 0 !important;
				overflow: hidden;
				min-width: 300px;
				max-width: 80%;

				@media (max-width: ${theme.breakpoints[0]}) {
					width: 95% !important;
					margin: 8px auto;
					min-width: unset;
					max-width: unset;
				}
			`}
		>
			<Flex
				bg="white"
				flexDirection="column"
				css={(theme: Theme) => css`
					border: 4px solid ${theme.colors.primary};
				`}
			>
				<Flex width={1} alignItems="center" justifyContent="space-between">
					<Text mx={2} fontSize="lg">
						Akce probíhající <b>{day.toLocaleDateString('cs')}</b>
					</Text>
					<Button
						variant="text"
						color="primary"
						p={2}
						onClick={onClose}
						css={css`
							&:hover {
								background-color: rgba(0, 0, 0, 0.05);
							}
						`}
					>
						<MdClose size={24} />
					</Button>
				</Flex>
				<Flex p={[1, 5]} mt={[1, 0]}>
					<TileGrid isEmpty={false}>
						{events.map(ev => (
							<CompanyEventTile key={ev.id} {...ev} />
						))}
					</TileGrid>
				</Flex>
			</Flex>
		</Dialog>
	);
};
