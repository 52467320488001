/** @jsxImportSource @emotion/core */
import React from 'react';
import { css } from '@emotion/core';
import { MdEmail, MdPhone } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Flex } from 'components/styled';
import Avatar from 'components/styled/Avatar';
import ErrorScreen from 'components/error/ErrorScreen';
import Text from 'components/styled/Text';
import { NavLinkButton } from 'components/styled/Button';

import { Loader } from 'modules/loader';

import { useUserToken } from 'auth';
import { MedalPortfolioIcon } from 'assets';

import { getFileUrl } from 'api/fileApi';
import { useStudent } from 'api/studentApi';

const PersonInfo = () => {
	const user = useUserToken();
	const response = useStudent(user?.id as string);
	const { push } = useHistory();

	if (response.status === 'loading') {
		return <Loader />;
	}

	if (response.status === 'error' || !response.data) {
		return <ErrorScreen {...response} />;
	}
	const student = response.data;
	const photoUrl = getFileUrl(student?.photo);
	const hasMedal =
		student.medals?.some(medal => medal === 'KC_PORTFOLIO') ?? false;

	return (
		<Flex
			height={400}
			flexDirection="column"
			boxShadow="2px 2px 6px 2px rgb(0 0 0 / 7%)"
			textAlign="center"
			justifyContent="center"
			px={24}
			py={4}
			bg="white"
			mb={3}
			mt={3}
		>
			<Flex
				width={1}
				justifyContent="center"
				position="relative"
				height="fit-content"
				onClick={() => push('/student')}
				css={css`
					cursor: pointer;
				`}
			>
				<Avatar
					photo={photoUrl}
					size={150}
					customMedalRenderer={() =>
						hasMedal ? (
							<Flex position="absolute" bottom="-20px" right={40} width="unset">
								<MedalPortfolioIcon size={60} flexShrink={0} />
							</Flex>
						) : (
							<></>
						)
					}
				/>
			</Flex>
			<Flex
				width={1}
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				flexGrow={0}
				//mb={3}
			>
				<NavLinkButton my={0} p={0} variant="text" to="/student">
					<Text mt={4} pb={0} mb={0} fontSize="22px" fontWeight="bold">
						{student.fullName}
					</Text>
				</NavLinkButton>
				<Flex flexDirection="column" mt={1}>
					<Text my={1} width={1}>
						<Text as="span" color="primary" mr={2}>
							<MdEmail size={16} />
						</Text>
						{student.email}
					</Text>

					{student.phone ? (
						<Text my={1} width={1}>
							<Text as="span" color="primary" mr={2}>
								<MdPhone size={16} />
							</Text>
							{student.phone}
						</Text>
					) : (
						<>
							<Text my={1} width={1}>
								<NavLinkButton my={0} p={0} variant="text" to="/student/edit">
									<Text as="span" color="primary" mr={2}>
										<MdPhone size={16} />
									</Text>
									<b>Doplnit telefoní číslo</b>
								</NavLinkButton>
							</Text>
						</>
					)}
				</Flex>

				<NavLinkButton
					mt={2}
					py={2}
					width={1}
					variant="primary"
					to="/student/edit"
				>
					VYLEPŠIT PROFIL
				</NavLinkButton>
			</Flex>
		</Flex>
	);
};

export default PersonInfo;
