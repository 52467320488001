import { useQuery } from 'react-query';

import { getCompany } from 'api/companyApi';
import { getLoggedInPerson } from 'api/personApi';

import { ApiUser, isRecruiterUser } from 'auth/token';

const useUserInfo = (user?: ApiUser) =>
	useQuery(
		['userInfo', isRecruiterUser(user) ? user.companyId : ''],
		async (_: string, id: string) => {
			if (isRecruiterUser(user)) {
				const response = await getCompany(id);
				return {
					name: response?.name,
					photo: response?.logo?.id,
				};
			}
			const response = await getLoggedInPerson();
			return {
				name: response?.fullName,
				photo: response?.photo?.id,
				phone: response?.phone,
				email: response?.email,
			};
		},
	);

export default useUserInfo;
