/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';
import { FieldMetaProps } from 'formik/dist/types';
import { FlexboxProps, space, SpaceProps } from 'styled-system';

import { BoxProps, Flex } from 'components/styled';
import Text from 'components/styled/Text';
import { resetButtonStyle } from 'components/styled/Button';

import ErrorFeedback from '../error/ErrorFeedback';
import { Label } from '../input/TextInput';

import styled from 'theme/styled';
import { FocusStyle } from 'theme/GlobalStyles';

const RadioFill = styled.div`
	background: currentColor;
	border-radius: 50%;
	width: 12px;
	height: 12px;
`;

const StyledRadio = styled.button<
	{ checked?: boolean; hasError?: boolean; disabled?: boolean } & SpaceProps
>`
	${resetButtonStyle}
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	border: 1px solid currentColor;
	border-radius: 50%;
	padding: 0;
	color: ${p => {
		if (p.disabled) {
			return p.theme.colors.darkerGrey;
		} else if (p.hasError) {
			return p.theme.colors.error;
		}
		return p.theme.colors.primary;
	}};

	& > div {
		visibility: ${p => (p.checked ? 'visible' : 'hidden')};
	}

	${space}

	:focus {
		${p => FocusStyle(p.theme)}
	}
`;

const InputWrapper = styled(Flex)`
	padding: ${p => p.theme.space[1]}px 0;
	margin-bottom: ${p => p.theme.space[1]}px;
	font-size: ${p => p.theme.fontSizes.sm}px;
`;

type Option<T> = {
	value: T;
	label: string;
};

type Props<T> = {
	value?: T;
	options: Option<T>[];

	label?: string;
	labelType?: 'leftToInput' | 'aboveInput';
	labelMinWidth?: string;
	required?: boolean;

	error?: FieldMetaProps<boolean>['error'];
	touched?: boolean;
	disabled?: boolean;

	id: string;
	onSetValue: (id: string, value: T | null) => void;
	onSetTouched?: (id: string, value: boolean) => void;
} & FlexboxProps &
	BoxProps;

const RadioInput = <T extends unknown>({
	id,
	value,
	options,
	label,
	labelType,
	labelMinWidth = '110px',
	required,
	error,
	touched,
	disabled,
	onSetValue,
	onSetTouched,
	...flexProps
}: Props<T>) => {
	return (
		<Flex
			width={1}
			alignItems="flex-start"
			flexDirection={labelType === 'leftToInput' ? 'row' : 'column'}
		>
			{label && (
				<Label
					htmlFor={id}
					pr={labelType === 'leftToInput' ? 4 : 0}
					minWidth={labelMinWidth}
					required={required}
				>
					{label}:
				</Label>
			)}
			<Flex flexDirection="column" alignItems="flex-start" {...flexProps}>
				{options.map(o => (
					<InputWrapper
						key={o.label}
						width={1}
						alignItems="center"
						onClick={() => {
							if (disabled) {
								return;
							}
							onSetTouched?.(id, true);
							onSetValue(id, o.value);
						}}
					>
						<StyledRadio
							id={`${id}-${o.label}`}
							type="button"
							checked={o.value === value}
							mr={2}
							hasError={!!error && touched}
							disabled={disabled}
						>
							<RadioFill />
						</StyledRadio>
						<Text
							as={p => <label htmlFor={`${id}-${o.label}`} {...p} />}
							css={theme => css`
								border-bottom: ${!!error &&
								touched &&
								`1px solid ${theme.colors.error}`};
								padding-bottom: ${!!error && touched && '4px'};
							`}
						>
							{o.label}
						</Text>
					</InputWrapper>
				))}
				{error && touched && <ErrorFeedback>{error}</ErrorFeedback>}
			</Flex>
		</Flex>
	);
};
export default RadioInput;
