import { useQuery } from 'react-query';

import { api } from 'api';

import { UASParams, UASResult } from 'utils/UASTypes';

import { PopUpWindow, PopUpWindowAdminDto } from 'typing/endpoints';

export const POPUP_QKEY = 'popup-window-list';

const baseUrl = 'pop-up-windows';

const params: UASParams = {
	pageSize: -1,
	sorting: [{ order: 'DESC', sort: 'created' }],
};

export const usePopupWindowsList = () =>
	useQuery([POPUP_QKEY], () =>
		api()
			.post(baseUrl + '/parametrized/with-counters', {
				json: params,
			})
			.json<UASResult<PopUpWindowAdminDto>>(),
	);
export const usePopupWindowDetail = (id: string) =>
	useQuery(
		[POPUP_QKEY, id],
		() =>
			api()
				.get(baseUrl + '/' + id)
				.json<PopUpWindow>(),
		{ staleTime: 60000 },
	);

export const submitPopupWindow = (id: string, json: PopUpWindow) =>
	api().put(baseUrl + `/${id}`, { json });

export const deletePopupWindow = (id: string) =>
	api().delete(baseUrl + `/${id}`);

export const markPopupAsShown = (id: string) =>
	api().put(`assigned-${baseUrl}/${id}/mark-as-shown/`);

export const markPopupLinkAsClicked = (id: string) =>
	api().put(`assigned-${baseUrl}/${id}/mark-as-clicked/`, {
		throwHttpErrors: false,
	});

export const enablePopup = (id: string) => api().put(`${baseUrl}/${id}/enable`);

export const disablePopup = (id: string) =>
	api().put(`${baseUrl}/${id}/disable`);

export const useAssignedPopupWindowsList = () =>
	useQuery([POPUP_QKEY, 'assigned-windows'], () =>
		api()
			.post(`assigned-${baseUrl}/parametrized`, {
				json: { ...params, filter: [] },
			})
			.json<UASResult<PopUpWindow>>(),
	);
