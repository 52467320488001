import { api } from 'api';

import useInfiniteUASQuery from 'hooks/useInfiniteUASQuery';

import { UASParams, UASResult } from 'utils/UASTypes';

import { SuggestedHardskill, SuggestedHardskillDto } from 'typing/endpoints';

export const suggestedHardskillsCreate = (json: SuggestedHardskillDto) =>
	api()
		.post('suggested-hardskills/create', { json })
		.json<SuggestedHardskillDto>();

export const suggestedHardskillsUpdate = (hardskill: SuggestedHardskill) =>
	api().put(`suggested-hardskills/${hardskill.id}`, {
		json: hardskill,
	});

export const suggestedHardskillsResolve = (
	id: string,
	verdict: boolean,
	rejectedReasoning: string,
) =>
	api().put(`suggested-hardskills/${id}/resolve?verdict=${verdict}`, {
		body: rejectedReasoning,
	});
export const useSuggestedHardskills = (json: UASParams) =>
	useInfiniteUASQuery<SuggestedHardskill, [UASParams]>(
		['suggested-hardskills', json],
		(_key, json, page = 0) =>
			api()
				.post('suggested-hardskills/parametrized', { json: { ...json, page } })
				.json<UASResult<SuggestedHardskill>>(),
	);
