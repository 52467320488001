/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { forwardRef, HTMLAttributes } from 'react';

import { resetButtonStyle } from 'components/styled/Button';
import { FlexProps, Flex } from 'components/styled';

import { CrossIcon } from 'assets';
import { Theme } from 'theme';

type ChipProps = (
	| { variant: 'withCross'; onDelete: () => void }
	| { variant: 'delete'; onDelete: () => void }
	| { variant?: 'default'; onDelete?: never }
) & {
	inverted?: boolean;
	size?: 'small' | 'large';
} & FlexProps &
	HTMLAttributes<HTMLDivElement>;

const Chip = forwardRef<HTMLDivElement, ChipProps>(
	(
		{
			variant = 'default',
			onDelete,
			inverted,
			size = 'large',
			children,
			...props
		},
		ref,
	) => (
		<Flex
			ref={ref}
			as={
				variant === 'delete' || props.onClick
					? p => <button type="button" {...p} css={resetButtonStyle} />
					: undefined
			}
			alignItems="center"
			justifyContent="space-between"
			px={size === 'large' ? [2, 3] : 2}
			py={size === 'large' ? [1, 2] : 1}
			my={['4px', 1]}
			fontSize={size === 'large' ? ['sm', 'md'] : 'sm'}
			mr={2}
			bg={inverted ? 'darkerGrey' : 'unset'}
			color={inverted ? 'white' : 'textColor'}
			{...props}
			onClick={variant === 'delete' ? onDelete : props.onClick}
			css={(theme: Theme) => css`
				border-radius: 20px;

				${!inverted &&
				css`
					border: 1px solid ${theme.colors.darkerGrey};
				`}

				${(props.onClick !== undefined || variant === 'delete') &&
				css`
					cursor: pointer;
					&:hover {
						background-color: ${inverted
							? theme.colors.secondary
							: theme.colors.primaryLight};
					}
				`}

			${variant === 'delete' &&
				css`
					&:hover svg {
						color: ${theme.colors.error};
					}
				`}
			`}
		>
			{children}
			{variant !== 'default' && (
				<CrossIcon
					ml={[1, 2]}
					size={[12, 'unset']}
					onClick={(e: React.MouseEvent) => {
						e.stopPropagation();
						onDelete?.();
					}}
					cursor="pointer"
					css={(theme: Theme) => css`
						&:hover {
							color: ${theme.colors.error};
						}
					`}
				/>
			)}
		</Flex>
	),
);
Chip.displayName = 'Chip';
export default Chip;
