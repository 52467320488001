import { QueryResult, useQuery } from 'react-query';

import { responseWithData } from 'utils';
import { useUserToken } from 'auth';

import { isAdminUser, isRecruiterUser } from 'auth/token';

import useInfiniteUASQuery from 'hooks/useInfiniteUASQuery';

import { UASParams, UASResult } from 'utils/UASTypes';

import { api } from '.';

import {
	Company,
	CompanyDto,
	CompanyRegistrationDto,
	FileRef,
} from 'typing/endpoints';

// Registration

export const registerCompany = (company: CompanyRegistrationDto) =>
	responseWithData<{ message: string }>(
		api({ skipToken: true }).post('companies/register', {
			json: company,
			throwHttpErrors: false,
		}),
	);

export const checkCompanyEmail = (email: string) =>
	responseWithData<boolean>(
		api({ skipToken: true }).get(`companies/register/check-email/${email}`),
	);

export const checkCompanyIco = (ico: CompanyRegistrationDto['ico']) =>
	responseWithData<boolean>(
		api({ skipToken: true }).get(`companies/register/check-ic/${ico}`),
	);

// Company

export const getCompany = (companyId: string) =>
	api().get(`companies/${companyId}`).json<Company>();

const getCompanyDto = (companyId: string) =>
	api().get(`companies/${companyId}/dto`).json<CompanyDto>();

export const useCompanyDtoPublic = (companyId?: string) =>
	useQuery<CompanyDto>(['company', companyId], () =>
		api({ skipToken: true })
			.get(`companies/${companyId ?? ''}/dto`)
			.json<CompanyDto>(),
	);

export const useCompany = <T extends Company | CompanyDto = Company>(
	companyId?: string,
	useDto?: boolean,
) =>
	useQuery<Company | CompanyDto>(
		['company', companyId, useDto],
		async (_key: string, id: string, useDto: boolean) => {
			if (!id) {
				return await new Promise<Company | CompanyDto>((_, reject) => reject());
			}
			return await (useDto ? getCompanyDto(id) : getCompany(id));
		},
	) as QueryResult<T, unknown>;

export const useCompanyWithoutAutoRefetch = (companyId: string) =>
	useQuery<Company>(['company', companyId], () => getCompany(companyId), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchInterval: Infinity,
		refetchOnReconnect: false,
	});

export const useLoggedInCompany = () => {
	const token = useUserToken();
	const companyId = isRecruiterUser(token) ? token.companyId : undefined;
	const companyResponse = useQuery(
		'logged-in-company',
		async () =>
			!companyId
				? new Promise<Company>((_, reject) => reject())
				: getCompany(companyId),
		{ enabled: !!companyId },
	);

	return { isCompany: isRecruiterUser(token), ...companyResponse };
};

// Companies

const getCompanies = (json: UASParams) =>
	api().post(`companies/parametrized`, { json }).json<UASResult<Company>>();

const getCompanyDtos = (json: UASParams) =>
	api().post('companies/dto', { json }).json<UASResult<CompanyDto>>();

export const useCompaniesPaginated = (json: UASParams) => {
	const token = useUserToken();
	const useDto = !isAdminUser(token);
	return useInfiniteUASQuery<Company | CompanyDto, [UASParams, boolean]>(
		['companies', json, useDto],
		(_key, params, useDto) =>
			useDto
				? getCompanyDtos({ ...params, page: params.page })
				: getCompanies({ ...params, page: params.page }),
	);
};

export const useCompanies = (json: UASParams, paginated?: boolean) => {
	const token = useUserToken();
	const useDto = !isAdminUser(token);
	return useInfiniteUASQuery<Company | CompanyDto, [UASParams, boolean]>(
		['companies', json, useDto],
		(_key, params, useDto, page = 0) =>
			useDto
				? getCompanyDtos({ ...params, page: paginated ? params.page : page })
				: getCompanies({ ...params, page: paginated ? params.page : page }),
	);
};

export const useLatestCompanies = () =>
	useQuery('companies/latest-registered/', () =>
		api({ skipToken: true })
			.get('companies/latest-registered/')
			.json<
				Pick<
					CompanyDto,
					'name' | 'logo' | 'photo' | 'id' | 'isAcceptingForeignStudents'
				>[]
			>(),
	);

// Edit

export const updateCompanyProfile = (profile: CompanyDto) =>
	api().put(`companies/${profile.id}/update`, { json: profile });

export const companiesPublish = (companyId: string) =>
	api().put(`companies/${companyId}/publish`);

// Activation

export const companiesActivate = (companyId: string) =>
	api().put(`companies/${companyId}/activate`);

export const companiesDeactivate = (companyId: string) =>
	api().put(`companies/${companyId}/deactivate`);

// Delete company
export const deleteCompany = (companyId: string) =>
	api().delete(`companies/${companyId}/remove`, { throwHttpErrors: false });

// Admin export

export const exportCompanies = (params: UASParams) =>
	api()
		.post(`companies/export`, { json: { ...params, pageSize: -1 } })
		.json<FileRef>();

// Follow

export const companiesFollow = (companyId: string) =>
	api().put(`companies/${companyId}/follow`);

export const companiesUnfollow = (companyId: string) =>
	api().delete(`companies/${companyId}/unfollow`);

// Admin assign credits
export const assignCreditsToCopmany = (
	companyId: string,
	options: { offersCount: number; revealsCount: number },
) =>
	api().put(
		`companies/${companyId}/assign-credits?${`offersCount=${options.offersCount}&`}${`revealsCount=${options.revealsCount}`}`,
	);
