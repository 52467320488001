/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { FC } from 'react';

import { Flex } from 'components/styled';
import Text from 'components/styled/Text';

const ProgressBar: FC<{
	stepsTotal: number;
	currentStep: number;
	profileCompleteness: number;
}> = ({ stepsTotal, currentStep, profileCompleteness }) =>
	currentStep < stepsTotal - 1 ? (
		<Flex
			width={1}
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<Flex
				width={1}
				bg="white"
				css={css`
					border: 1px solid #aaa;
					border-radius: 10px;
				`}
			>
				<Flex
					css={css`
						border: 0px solid #ffffff;
						border-radius: 10px;
					`}
					bg="primary"
					//width={`${(100 / stepsTotal) * (currentStep + 1)}%`}
					width={`${profileCompleteness}%`}
					height={10}
				/>
			</Flex>
			<Text fontSize="sm" mt={2}>
				Efektivita profilu: {profileCompleteness} %
			</Text>
		</Flex>
	) : (
		<></>
	);

export default ProgressBar;
