/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React, { useContext } from 'react';
import { background, color } from 'styled-system';
import { noop } from 'lodash-es';

import styled from 'theme/styled';
import { FocusStyle } from 'theme/GlobalStyles';

import * as T from './_typing';
import { FloatingMenuContext } from './utils';

const SubButtonStyled = styled.button<T.StyledSubButtonProps>`
	position: relative;
	display: flex;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	outline: none;
	padding: 0;
	-webkit-user-drag: none;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	opacity: ${p => (p.isOpen ? 1 : 0)};
	transition: all ${p => p.slideSpeed}ms;
	width: ${p => p.size}px;
	height: ${p => p.size}px;
	margin-top: ${p => (p.direction === 'down' ? p.spacing : 0)}px;
	margin-bottom: ${p => (p.direction === 'up' ? p.spacing : 0)}px;
	margin-left: ${p => (p.direction === 'right' ? p.spacing : 0)}px;
	margin-right: ${p => (p.direction === 'left' ? p.spacing : 0)}px;
	pointer-events: ${p => (p.isOpen ? 'auto' : 'none')};

	${p =>
		!p.isOpen &&
		css`
			margin-top: ${p.direction === 'up' ? -p.size : 0}px;
			margin-bottom: ${p.direction === 'down' ? -p.size : 0}px;
			margin-left: ${p.direction === 'left' ? -p.size : 0}px;
			margin-right: ${p.direction === 'right' ? -p.size : 0}px;
		`}

	&:focus {
		${p => FocusStyle(p.theme)}
	}

	${background}
	${color}
  
  ${p =>
		!p.disabled &&
		css`
			cursor: pointer;
		`}
  
  ${p =>
		p.disabled &&
		css`
			background-color: ${p.theme.colors.darkerGrey};
		`}

  ${p =>
		p.tooltip &&
		css`
			::before {
				content: '${p.tooltip}';
				display: block;
				position: absolute;
				right: ${p.size}px;
				white-space: nowrap;
				margin-right: 12px;
				padding: 4px 8px;
				color: #ffffff;
				text-align: center;
				background-color: rgba(0, 0, 0, 0.75);
				border-radius: 4px;
				font-weight: normal;
			}
		`}
`;

const SubButton: React.FC<
	T.SubButtonProps & Partial<React.ComponentProps<typeof SubButtonStyled>>
> = ({ size = 40, onClick = noop, icon, ...rest }) => {
	const context = useContext(FloatingMenuContext);
	return (
		<SubButtonStyled
			size={size}
			{...context}
			{...rest}
			onClick={context.isOpen ? onClick : noop}
		>
			{icon}
		</SubButtonStyled>
	);
};

export default SubButton;
