/** @jsxImportSource @emotion/core */
import { css } from '@emotion/core';
import React from 'react';

import { Flex } from 'components/styled';

import { Theme } from 'theme';

const IconBubble: React.FC = ({ children }) => (
	<Flex
		bg="lightGrey"
		width={64}
		height={64}
		alignItems="center"
		justifyContent="center"
		css={(theme: Theme) => css`
			border-radius: 50%;
			border: 1px solid ${theme.colors.darkerGrey};
		`}
	>
		{children}
	</Flex>
);

export default IconBubble;
